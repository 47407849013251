/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { api } from "util";
// import { useNavigate } from "react-router-dom";
// import MDBox from "components/MDBox";

export default function EditNewsArtical({ selected, onClose }) {
  // const [package1, setPackage1] = useState("");
  const [articalvar, setartical] = useState("");
  const [ResponsData, setResponsData] = useState("");

  const [confirmation, setConfirmation] = useState(false);
  const [reloadData, setreloadData] = useState(false);

  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);
  // window.location.reload();
  // const navigate = useNavigate();

  const EditTopicreq = async (channelIdup,channelNameUp) => {
    try {
      const { data } = await api.patch(`/content/update/contant/artical`, {
        contantlId: selected?.NewsId,
        contantActical: articalvar
      });
      setResponsData(data);
      setreloadData(true);
    } catch (error) {
      setResponsData(error?.response?.data);
    }
  };

  useEffect(() => {
    if(!selected) return;

    setartical(selected?.NewsContant)
    setreloadData(false);
  }, [selected]);

  // console.log("Noinput",ChannelNamep)
  
  return (
    <Dialog
      open={!!selected}
      // open={() => {
      //   if (!!selected) {
      //     return false
      //     // console.log("2",selected)
      //     // setChannelNamep(selected.NewsContant)
      //   } else {
      //     console.log("Noinput")
          
      //   }
      // }}
      // onChange={setChannelNamep(selected?.NewsContant)}
      onClose={() => {
        setConfirmation(false);
        onClose();
      }}
      // onOpen={() => setChannelNamep(selected.NewsContant)}
      // onDataChange={() => setChannelNamep(selected.NewsContant)}
      fullWidth
    >
      <DialogTitle>Edit News :- {selected?.Newstitle}</DialogTitle>
      <DialogContent>
        {!confirmation ? (
          <Stack spacing={2} mt={1}>
            <h4>Artical</h4>
            <TextareaAutosize fullWidth minRows={10} maxRows={15} value={articalvar} onChange={(e) => setartical(e.target.value)} />
          </Stack>
        ) : (
          <Stack spacing={2} mt={1}>
            {ResponsData.status && <Typography>Confirmation</Typography>}
            {!ResponsData.status && <Typography>Error</Typography>}
            <Typography>{ResponsData?.message ?? "Something went wrong"}</Typography>
          </Stack>
        )}
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={2} alignItems="center">
          <MDButton
            component={Button}
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => {
              if (!confirmation) {
                setConfirmation(true);
                EditTopicreq(selected.channelId,articalvar);
              } else {
                setConfirmation(false);
                onClose();
                if (reloadData === true) {
                  window.location.reload();
                }
              }
            }}
          >
            {!confirmation ? "Submit" : "Close"}
          </MDButton>
        </Stack>
      </DialogActions>

    </Dialog>
  );
}
