/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Stack } from "@mui/material";
import MDButton from "components/MDButton";
import moment from "moment";

export default function MemberTransactionTable({ users, page, nextPage, prevPage }) {
  return (
    <TableContainer sx={{ mt: 3, px: 3 }}>
      <Table>
        <TableRow>
          <TableCell sx={{ fontWeight: "900" }}>Transaction ID</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Seller</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Purchaser</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Sold At</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Membership Name</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Membership Type</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Screen Count</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Device Type</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Date of Expiry</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Transaction Type</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Transaction on</TableCell>
        </TableRow>
        <TableBody>
          {users?.map(
            ({
              TransactionID,
              Seller,
              Purchaser,
              SellAt,
              PackName,
              DeviceType,
              PackType,
              ExpiryTime,
              TransactionType,
              TransactionAt,
              index,
            }) => (
              <TableRow key={index}>
                <TableCell>{TransactionID}</TableCell>
                <TableCell>{Seller}</TableCell>
                <TableCell>{Purchaser}</TableCell>
                <TableCell>
                  {"\u20b9"} {SellAt}
                </TableCell>
                <TableCell>{PackName}</TableCell>
                <TableCell>{PackType}</TableCell>
                <TableCell>1</TableCell>
                <TableCell>{DeviceType}</TableCell>
                <TableCell>{moment(ExpiryTime * 1000).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                <TableCell>{TransactionType}</TableCell>
                <TableCell>{moment(TransactionAt).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>

      <Stack direction="row" my={4} justifyContent="flex-end" spacing={6} px={2}>
        {page > 1 && (
          <MDButton
            color="info"
            size="small"
            onClick={() => {
              prevPage();
            }}
          >
            Prev
          </MDButton>
        )}
        <MDButton
          color="info"
          size="small"
          onClick={() => {
            nextPage();
          }}
        >
          Next
        </MDButton>
      </Stack>
    </TableContainer>
  );
}
