/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "userReducer",
  initialState: {
    user: null,
  },
  reducers: {
    userLogin: (state, action) => {
      state.user = action.payload;

      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    userLogout: (state) => {
      state.user = null;

      localStorage.removeItem("user");
    },
  },
});

// Action creators
export const { userLogin, userLogout } = userSlice.actions;
export default userSlice.reducer;
