/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  styled,
} from "@mui/material";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import { useSnackbar } from "notistack";
import { api } from "util";
import tvlogo from "../../assets/Tv.png";
import phoneicon from "../../assets/Phone.png";
import tvlogoPlusephoneicon from "../../assets/Tv+Phone.png";

export default function PackageModal({ selected, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [PackagePhone, setPackagePhone] = useState("");
  const [PackageTv, setPackageTv] = useState("");
  const [PackageBoth, setPackageBoth] = useState("");
  // const [Screen, setScreen] = useState("");
  // const [price, setPrice] = useState("");

  const [PackageListTV, setPackageListTV] = useState([]);
  const [PackageListPhone, setPackageListPhone] = useState([]);
  const [PackageListBoth, setPackageListBoth] = useState([]);

  const [ScreenListTv, setScreenListTv] = useState([]);
  const [ScreenDataTv, setScreenDataTv] = useState("");
  const [PackagesContentListTv, setPackagesContentListTv] = useState([]);

  const [ScreenListPhone, setScreenListPhone] = useState([]);
  const [ScreenDataPhone, setScreenDataPhone] = useState("");
  const [PackagesContentListPhone, setPackagesContentListPhone] = useState([]);

  const [ScreenListBoth, setScreenListBoth] = useState([]);
  const [ScreenDataBoth, setScreenDataBoth] = useState("");
  const [PackagesContentListBoth, setPackagesContentListBoth] = useState([]);

  const [PageName, setPageName] = useState("DeviceMenu");
  const [MenuTitel, setMenuTitel] = useState("Select Device for Package");

  const [confirmation, setConfirmation] = useState(false);
  const [reloadData, setreloadData] = useState(false);

  const [priceDataTv, setpriceDataTv] = useState(null);
  const [priceDataMobil, setpriceDataMobil] = useState(null);
  const [priceDataBoth, setpriceDataBoth] = useState(null);

  const [IsRewnewPhone, setIsRewnewPhone] = useState(false);
  const [IsRewnewTv, setIsRewnewTv] = useState(false);
  const [IsRewnewBoth, setIsRewnewBoth] = useState(false);

  const PackagesReq = async (DeviceTvpe) => {
    try {
      const { data } = await api.get(`/subscription/showPackages`, {
        params: { pageType: "create", userDevice: DeviceTvpe },
      });
      if (DeviceTvpe === "Tv") {
        setPackageListTV(data.resalt);
        // setScreenListTv([]);
        // setPackagesContentListTv([]);
      } else if (DeviceTvpe === "Phone") {
        setPackageListPhone(data.resalt);
        // setScreenListPhone([]);
        // setPackagesContentListPhone([]);
      } else if (DeviceTvpe === "Both") {
        setPackageListBoth(data.resalt);
        // setScreenListPhone([]);
        // setPackagesContentListPhone([]);
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  const PackagesScreenReq = async (PackID, DeviceTvpe) => {
    try {
      const { data } = await api.get(`/subscription/showPackageScrrens`, {
        params: { packId: PackID },
      });
      if (DeviceTvpe === "Tv") {
        setScreenListTv(data.resalt);
        setScreenDataTv("");
      } else if (DeviceTvpe === "Phone") {
        setScreenListPhone(data.resalt);
        setScreenDataPhone("");
      } else if (DeviceTvpe === "Both") {
        setScreenListBoth(data.resalt);
        setScreenDataBoth("");
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
      setreloadData(false);
    }
  };

  const PackagesContentReq = async (PackID, DeviceTvpe) => {
    try {
      const { data } = await api.get(`/subscription/showPackageContent`, {
        params: { packId: PackID },
      });
      if (DeviceTvpe === "Tv") {
        setPackagesContentListTv(data.resalt);
      } else if (DeviceTvpe === "Phone") {
        setPackagesContentListPhone(data.resalt);
      } else if (DeviceTvpe === "Both") {
        setPackagesContentListBoth(data.resalt);
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  const createCustumar = async () => {
    try {
      let Dddevice = [];
      if (priceDataBoth) {
        const bothdevice = {
          deviceType: "Both",
          planId: PackageBoth,
          screenId: ScreenDataBoth,
          IsAutoRenew: IsRewnewBoth,
        };
        Dddevice = [];
        Dddevice.push(bothdevice);
        if (priceDataTv || priceDataMobil) {
          enqueueSnackbar(
            "You cannot select both the TV and Phone options when choosing the All Devices package",
            {
              variant: "error",
            }
          );
        }
      } else {
        if (priceDataTv) {
          const tvdevice = {
            deviceType: "Tv",
            planId: PackageTv,
            screenId: ScreenDataTv,
            IsAutoRenew: IsRewnewTv,
          };
          Dddevice.push(tvdevice);
        }
        if (priceDataMobil) {
          const phonedevice = {
            deviceType: "Phone",
            planId: PackagePhone,
            screenId: ScreenDataPhone,
            IsAutoRenew: IsRewnewPhone,
          };
          Dddevice.push(phonedevice);
        }
      }
      const { data } = await api.post(`/subscription/create`, {
        phoneNumber: parseInt(selected.mobileNumber, 10),
        name: selected.name,
        email: selected.email,
        gender: selected.gender,
        address: selected.address,
        country: "IN",
        state: selected.state,
        city: selected.city,
        pincode: selected.pincode,
        Device: Dddevice,
      });
      enqueueSnackbar(data?.message, { variant: "success" });
      setConfirmation(true);
      setreloadData(true);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
      setreloadData(false);
    }
  };

  const HeaderTypography = styled(Typography)({
    fontSize: 14,
  });

  useEffect(() => {
    setreloadData(false);
    // setpriceData(1.0);
    // PackagesReq();
    // if (!PackageList) {
    //   setPackageList([]);
    // }
    // if (!ScreenList) {
    //   setScreenList([]);
    // }
  }, []);

  return (
    <Dialog
      open={!!selected}
      onClose={() => {
        setConfirmation(false);
        onClose();
        // setpriceData(null);
      }}
      fullWidth
    >
      <DialogTitle>{MenuTitel}</DialogTitle>
      <DialogContent>
        {PageName === "DeviceMenu" && (
          <Stack spacing={2} mt={1}>
            <Grid item xs={20}>
              <Paper sx={{ p: 1 }}>
                <Stack
                  direction="row"
                  px={2}
                  py={0}
                  sx={{ height: 50 }}
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={async () => {
                    await setPageName("Tv");
                    await setMenuTitel("TV Packages");
                    await PackagesReq("Tv");
                  }}
                >
                  <img src={tvlogo} style={{ width: 70, height: 70, borderRadius: 5 }} alt="Tv" />
                  <HeaderTypography> TV </HeaderTypography>
                  {/* <MDButton color="info" size="small" sx={{ textTransform: "none" }}>
                    Log
                  </MDButton> */}
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={20}>
              <Paper sx={{ p: 1 }}>
                <Stack
                  direction="row"
                  px={2}
                  py={0}
                  sx={{ height: 50 }}
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={async () => {
                    await setPageName("Phone");
                    await setMenuTitel("Mobile Packages");
                    await PackagesReq("Phone");
                  }}
                >
                  <img
                    src={phoneicon}
                    style={{ width: 60, height: 60, borderRadius: 5 }}
                    alt="Mobile"
                  />
                  <HeaderTypography> Mobile </HeaderTypography>
                  {/* <MDButton color="info" size="small" sx={{ textTransform: "none" }}>
                    Log
                  </MDButton> */}
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={20}>
              <Paper sx={{ p: 1 }}>
                <Stack
                  direction="row"
                  px={2}
                  py={0}
                  sx={{ height: 50 }}
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={async () => {
                    await setPageName("Both");
                    await setMenuTitel("Tv + Mobile Packages");
                    await PackagesReq("Both");
                  }}
                >
                  <img
                    src={tvlogoPlusephoneicon}
                    style={{ width: 60, height: 60, borderRadius: 5 }}
                    alt="Tv + Mobile Packages"
                  />
                  <HeaderTypography> Tv + Mobile </HeaderTypography>
                  {/* <MDButton color="info" size="small" sx={{ textTransform: "none" }}>
                    Log
                  </MDButton> */}
                </Stack>
              </Paper>
            </Grid>
          </Stack>
        )}

        {PageName === "Phone" && (
          <Stack spacing={2} mt={1}>
            <FormControl fullWidth>
              <InputLabel>Packages</InputLabel>
              <Select
                value={PackagePhone}
                label="Packages"
                onChange={(e) => {
                  setPackagePhone(e.target.value);
                  PackagesScreenReq(e.target.value, "Phone");
                  PackagesContentReq(e.target.value, "Phone");
                }}
                sx={{ height: 45 }}
              >
                {PackageListPhone?.map(({ PackageName: packageName, Id: PackageId }) => (
                  <MenuItem key={packageName} value={PackageId}>
                    {packageName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Screen</InputLabel>
              <Select
                value={ScreenDataPhone}
                label="Screen"
                onChange={(e) => {
                  ScreenListPhone.forEach((obj) => {
                    if (obj.Package_price_id === e.target.value) {
                      setpriceDataMobil(obj);
                    }
                  });
                  setScreenDataPhone(e.target.value);
                }}
                sx={{ height: 45 }}
              >
                {ScreenListPhone?.map(
                  ({ Screen_count: Scrennount, Package_price_id: PackPrice }) => (
                    <MenuItem key={Scrennount} value={PackPrice}>
                      {Scrennount}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <Box
              sx={{
                border: 1,
                borderRadius: 2,
                borderColor: "#ccc",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <Stack
                alignSelf="center"
                sx={{
                  p: 3,
                  maxHeight: "30vh",
                  overflowY: "scroll",

                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,.1)",
                  },
                }}
                spacing={1}
              >
                {PackagesContentListPhone.map(({ AppName: appName, AppIcon: appIcon }) => (
                  <Stack direction="row" justifyContent="space-between" alignItems="center" px={4}>
                    <img
                      src={appIcon}
                      style={{ width: 80, height: 40, borderRadius: 5 }}
                      alt="package"
                    />
                    <Typography>{appName}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
            <MDBox>
              {priceDataMobil && (
                <Typography variant="h4">
                  Price: {"\u20b9"}
                  {parseFloat(priceDataMobil.Sell_price)} (Enable)
                </Typography>
              )}
              {/* {priceDataMobil && (
                <Typography variant="h4">
                  Sell @: {"\u20b9"}
                  {parseFloat(priceDataMobil.Sell_price)} (Enable)
                </Typography>
              )}
              {priceDataMobil && (
                <Typography variant="h4">
                  Discount: {"\u20b9"}
                  {parseFloat(priceDataMobil.Mrp) - parseFloat(priceDataMobil.Sell_price)} (Enable)
                </Typography>
              )} */}
            </MDBox>
            <FormControlLabel
              control={
                <Checkbox
                  checked={IsRewnewPhone}
                  onChange={(e) => {
                    setIsRewnewPhone(e.target.checked);
                  }}
                />
              }
              label="Auto Renew"
            />
            <MDButton
              component={Button}
              variant="gradient"
              color="info"
              size="medium"
              onClick={() => {
                setPageName("DeviceMenu");
                setMenuTitel("Select Device for Package");
              }}
            >
              Back to Device
            </MDButton>
          </Stack>
        )}

        {PageName === "Tv" && (
          <Stack spacing={2} mt={1}>
            <FormControl fullWidth>
              <InputLabel>Packages</InputLabel>
              <Select
                value={PackageTv}
                label="Packages"
                onChange={(e) => {
                  setPackageTv(e.target.value);
                  PackagesScreenReq(e.target.value, "Tv");
                  PackagesContentReq(e.target.value, "Tv");
                }}
                sx={{ height: 45 }}
              >
                {PackageListTV?.map(({ PackageName: packageName, Id: PackageId }) => (
                  <MenuItem key={packageName} value={PackageId}>
                    {packageName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Screen</InputLabel>
              <Select
                value={ScreenDataTv}
                label="Screen"
                onChange={(e) => {
                  ScreenListTv.forEach((obj) => {
                    if (obj.Package_price_id === e.target.value) {
                      setpriceDataTv(obj);
                    }
                  });
                  setScreenDataTv(e.target.value);
                }}
                sx={{ height: 45 }}
              >
                {ScreenListTv?.map(({ Screen_count: Scrennount, Package_price_id: PackPrice }) => (
                  <MenuItem key={Scrennount} value={PackPrice}>
                    {Scrennount}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box
              sx={{
                border: 1,
                borderRadius: 2,
                borderColor: "#ccc",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <Stack
                alignSelf="center"
                sx={{
                  p: 3,
                  maxHeight: "30vh",
                  overflowY: "scroll",

                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,.1)",
                  },
                }}
                spacing={1}
              >
                {PackagesContentListTv.map(({ AppName: appName, AppIcon: appIcon }) => (
                  <Stack direction="row" justifyContent="space-between" alignItems="center" px={4}>
                    <img
                      src={appIcon}
                      style={{ width: 60, height: 40, borderRadius: 5 }}
                      alt="package"
                    />
                    <Typography>{appName}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
            <MDBox>
              {priceDataTv && (
                <Typography variant="h4">
                  Price: {"\u20b9"}
                  {parseFloat(priceDataTv.Sell_price)} (Enable)
                </Typography>
              )}
            </MDBox>
            <FormControlLabel
              control={
                <Checkbox
                  checked={IsRewnewTv}
                  onChange={(e) => {
                    setIsRewnewTv(e.target.checked);
                  }}
                />
              }
              label="Auto Renew"
            />
            <MDButton
              component={Button}
              variant="gradient"
              color="info"
              size="medium"
              onClick={() => {
                setPageName("DeviceMenu");
                setMenuTitel("Select Device for Package");
              }}
            >
              Back to Device
            </MDButton>
          </Stack>
        )}

        {PageName === "Both" && (
          <Stack spacing={2} mt={1}>
            <FormControl fullWidth>
              <InputLabel>Packages</InputLabel>
              <Select
                value={PackageBoth}
                label="Packages"
                onChange={(e) => {
                  setPackageBoth(e.target.value);
                  PackagesScreenReq(e.target.value, "Both");
                  PackagesContentReq(e.target.value, "Both");
                }}
                sx={{ height: 45 }}
              >
                {PackageListBoth?.map(({ PackageName: packageName, Id: PackageId }) => (
                  <MenuItem key={packageName} value={PackageId}>
                    {packageName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Screen</InputLabel>
              <Select
                value={ScreenDataBoth}
                label="Screen"
                onChange={(e) => {
                  ScreenListBoth?.forEach((obj) => {
                    if (obj.Package_price_id === e.target.value) {
                      setpriceDataBoth(obj);
                    }
                  });
                  setScreenDataBoth(e.target.value);
                }}
                sx={{ height: 45 }}
              >
                {ScreenListBoth?.map(
                  ({ Screen_count: Scrennount, Package_price_id: PackPrice }) => (
                    <MenuItem key={Scrennount} value={PackPrice}>
                      {Scrennount}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <Box
              sx={{
                border: 1,
                borderRadius: 2,
                borderColor: "#ccc",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <Stack
                alignSelf="center"
                sx={{
                  p: 3,
                  maxHeight: "30vh",
                  overflowY: "scroll",

                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,.1)",
                  },
                }}
                spacing={1}
              >
                {PackagesContentListBoth?.map(({ AppName: appName, AppIcon: appIcon }) => (
                  <Stack direction="row" justifyContent="space-between" alignItems="center" px={4}>
                    <img
                      src={appIcon}
                      style={{ width: 60, height: 40, borderRadius: 5 }}
                      alt="package"
                    />
                    <Typography>{appName}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
            <MDBox>
              {priceDataBoth && (
                <Typography variant="h4">
                  Price: {"\u20b9"}
                  {parseFloat(priceDataBoth.Sell_price)} (Enable)
                </Typography>
              )}
            </MDBox>
            <FormControlLabel
              control={
                <Checkbox
                  checked={setIsRewnewBoth}
                  onChange={(e) => {
                    setIsRewnewBoth(e.target.checked);
                  }}
                />
              }
              label="Auto Renew"
            />
            <MDButton
              component={Button}
              variant="gradient"
              color="info"
              size="medium"
              onClick={() => {
                setPageName("DeviceMenu");
                setMenuTitel("Select Device for Package");
              }}
            >
              Back to Device
            </MDButton>
          </Stack>
        )}
      </DialogContent>

      {/* "Tv" */}

      {PageName === "DeviceMenu" && (priceDataBoth || priceDataMobil || priceDataTv) && (
        <DialogActions>
          <Stack spacing={2} alignItems="center">
            <MDButton
              component={Button}
              variant="gradient"
              color="info"
              size="medium"
              onClick={() => {
                if (!confirmation) {
                  createCustumar();
                } else {
                  setConfirmation(false);
                  onClose();
                  if (reloadData === true) {
                    window.location.reload();
                  }
                }
              }}
            >
              {!confirmation ? "Submit" : "Close"}
            </MDButton>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}
