/* eslint-disable import/order */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import {
  Button,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import { api } from "util";
import { useSnackbar } from "notistack";
// import UserSearchTable from "./UserSearchTable";
import MDButton from "components/MDButton";
import AddShowModeal from "layouts/Show/AddShowModal";
import AddEpisodeModal from "layouts/Show/AddEpisodeModal";
// import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";

// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import moment from "moment";

function Show() {
  const { enqueueSnackbar } = useSnackbar();
  const [searchRes, SetsearchRes] = useState();
  const [username, setUsername] = useState();

  const [addingUser, setAddingUser] = useState();
  const [Topicedit, setTopicedit] = useState();
  const [imgData, setimgData] = useState();
  // const [ClintUserID, setClintUserID] = useState();
  // const [PackAlot, setPackAlot] = useState();
  // const [getPackassinData, setgetPackassinData] = useState();
  // const [Walletdata, setWalletdata] = useState();

  const SarchTopic = async (topicName) => {
    try {
      if (topicName === "") {
        const { data } = await api.get(`/content/get/channel`);
        SetsearchRes(data.resalt);
      } else {
        const { data } = await api.get(`/content/get/channel`, {
          params: { channel: topicName },
        });
        SetsearchRes(data.resalt);
      }
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.message ?? "Something went wrong",
        {
          variant: "error",
        }
      );
    }
  };

  const DeleteState = async (DeleteId) => {
    try {
      const { data } = await api.delete(`content/delete/channel/` + DeleteId);
      enqueueSnackbar(data?.message, { variant: "success" });
      SarchTopic("");
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.message ?? "Something went wrong",
        {
          variant: "error",
        }
      );
    }
  };

  useEffect(() => {
    SarchTopic("");
  }, []);

  return (
    <DashboardLayout>
      <AddShowModeal
        selected={addingUser}
        onClose={() => setAddingUser(null)}
      />
      <AddEpisodeModal
        selected={Topicedit}
        onClose={() => setTopicedit(null)}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Show
                </MDTypography>
              </MDBox>
              <MDBox pt={3} pl={5}>
                <Stack direction="row" spacing={4}>
                  <Select
                  value={username}
                  label="contentType"
                  onChange={(e) => setUsername(e.target.value)}
                  >
                    <MenuItem value="newsText">IND1</MenuItem>
                    <MenuItem value="newsVideo">IND2</MenuItem>
                  </Select>
                  
                  <MDButton
                    component={Button}
                    variant="gradient"
                    color="info"
                    size="medium"
                    type="submit"
                    onClick={() => setAddingUser({})}
                  >
                    Add Show
                  </MDButton>
                  <MDButton
                    component={Button}
                    variant="gradient"
                    color="info"
                    size="medium"
                    type="submit"
                    onClick={() => setTopicedit({})}
                  >
                    Add Episode
                  </MDButton>
                </Stack>
              </MDBox>
              <TableContainer component={Paper} sx={{ mt: 3, px: 3 }}>
                <Table>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "900" }}>Title</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>
                      Description
                    </TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Type</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Image</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Keyword</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Link</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Created @</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Operations</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}></TableCell>
                  </TableRow>
                  <TableBody>
                    <TableRow key={1}>
                      <TableCell>{"IND Show 1"}</TableCell>
                      <TableCell>{"मध्य प्रदेश में चुनावी तैयारियां तेज है। कांग्रेस सत्ता में वापसी का फार्मूला तैयार कर रही है। कांग्रेस ने अब टिकिट को लेकर भी नई रणनीति बनाई है (MP Congress)। कांग्रेस अपने उम्मीदवारों के नाम तीन चरणों मे घोषित करेगी। जानिए क्या है कांग्रेस की टिकिट को लेकर रणनीति।"}</TableCell>
                      <TableCell>{"Show"}</TableCell>
                      <TableCell>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          type="submit"
                          onClick={() => {
                            setimgData({
                              BIcon: "banner",
                              Incon: "n",
                              ChannelName: "channelName",
                            });
                          }}
                        >
                          View
                        </MDButton>
                      </TableCell>
                      <TableCell>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          type="submit"
                          onClick={() => {
                            setimgData({
                              BIcon: "banner",
                              Incon: "n",
                              ChannelName: "channelName",
                            });
                          }}
                        >
                          View
                        </MDButton>
                      </TableCell>
                      <TableCell>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          type="submit"
                          onClick={() => {
                            setimgData({
                              BIcon: "banner",
                              Incon: "n",
                              ChannelName: "channelName",
                            });
                          }}
                        >
                          open
                        </MDButton>
                      </TableCell>
                      <TableCell>
                        {moment(1684154923000).format("YYYY-MM-DD HH:mm:ss")}
                      </TableCell>
                      <TableCell>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          type="submit"
                          onClick={() => {
                            setimgData({
                              BIcon: "banner",
                              Incon: "n",
                              ChannelName: "channelName",
                            });
                          }}
                        >
                          Delete Show
                        </MDButton>
                      </TableCell>
                    </TableRow>
                    {/* {searchRes?.map(
                      ({
                        id,
                        channelName,
                        createdAt,
                        priority,
                        banner,
                        icon,
                        IsPackAssign,
                      }) => (
                        <TableRow key={id}>
                          <TableCell>{channelName}</TableCell>
                          <TableCell>{priority}</TableCell>
                          <TableCell>
                            {moment(createdAt ).format("YYYY-MM-DD HH:mm:ss")}
                          </TableCell>
                          <TableCell>
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              type="submit"
                              onClick={() => {
                                setimgData({ BIcon: banner,Incon: icon, ChannelName :channelName});
                              }}
                            >
                              View Icon
                            </MDButton>
                          </TableCell>
                          <TableCell>
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              type="submit"
                              onClick={() => {
                                setTopicedit({ Channelname_: channelName ,channelId :id});
                              }}
                            >
                              Edit State Name
                            </MDButton>
                          </TableCell>
                          <TableCell>
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              type="submit"
                              onClick={() => {
                                setTopicedit({ Channelname_: channelName ,channelId :id});
                              }}
                            >
                              Edit State Sequence
                            </MDButton>
                          </TableCell>
                          <TableCell>
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              type="submit"
                              onClick={() => {
                                DeleteState(id);
                              }}
                            >
                              Delete State
                            </MDButton>
                          </TableCell>
                        </TableRow>
                      )
                    )} */}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <UserSearchTable users={searchRes} /> */}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Show;
