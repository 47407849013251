/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const drawerSlice = createSlice({
  name: "drawerReducer",
  initialState: {
    drawer: false,
  },
  reducers: {
    drawerToggle: (state) => {
      state.drawer = !state.drawer;
    },
    drawerOpen: (state) => {
      state.drawer = true;
    },
    drawerClose: (state) => {
      state.drawer = false;
    },
  },
});
// Action creators
export const { drawerToggle, drawerOpen, drawerClose } = drawerSlice.actions;
export default drawerSlice.reducer;
