/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { api } from "util";
import { useSnackbar } from "notistack";
// import { useNavigate } from "react-router-dom";
// import MDBox from "components/MDBox";

export default function AddTopicModeal({ selected, onClose }) {
  // const [package1, setPackage1] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [topicName, settopicName] = useState("");
  const [ResponsData, setResponsData] = useState("");

  const [confirmation, setConfirmation] = useState(false);
  const [reloadData, setreloadData] = useState(false);

  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);
  // window.location.reload();
  // const navigate = useNavigate();

  const ADDTopicreq = async () => {
    try {
      const { data } = await api.post(`/content/add/genre`, {
        genreName: topicName,
      });
      setResponsData(data);
      setreloadData(true);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setreloadData(false);
  }, []);

  return (
    <Dialog
      open={!!selected}
      onClose={() => {
        setConfirmation(false);
        onClose();
      }}
      fullWidth
    >
      <DialogTitle>Add Topic</DialogTitle>
      <DialogContent>
        {!confirmation ? (
          <Stack spacing={2} mt={1}>
              <TextField
                label="Topic Name"
                fullWidth
                value={topicName}
                onChange={(e) => settopicName(e.target.value)}
            />

          </Stack>
        ) : (
          <Stack spacing={2} mt={1}>
            {ResponsData.success && <Typography>Confirmation</Typography>}
            {!ResponsData.success && <Typography>Error</Typography>}
            <Typography>{ResponsData.message}</Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} alignItems="center">
          <MDButton
            component={Button}
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => {
              if (!confirmation) {
                setConfirmation(true);
                ADDTopicreq();
              } else {
                setConfirmation(false);
                onClose();
                if (reloadData === true) {
                  window.location.reload();
                }
              }
            }}
          >
            {!confirmation ? "Submit" : "Close"}
          </MDButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
