/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // FormControl,
  // InputLabel,
  // MenuItem,
  // Select,
  Stack,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import MDButton from "components/MDButton";
import { api } from "util";
import { useSnackbar } from "notistack";

export default function PackageAssign({ selected, onClose }) {
  const [packageData, setpackageData] = useState([]);
  // setpackageData(selected?.Ata);
  // const [package1, setPackage1] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  // const [UserName, setUserName] = useState("");

  const [confirmation, setConfirmation] = useState(false);
  // const navigate = useNavigate();

  // const [UserIDFome,setUserID] = useState(false)
  // const [TransferAmountD, setTransferAmountD] = useState("");
  const [ResponsData, setResponsData] = useState("");
  const [reloadData, setreloadData] = useState(false);
  // const TansferAmount = async (UserIDFome, TransferAmount) => {
  //   try {
  //     const { data } = await api.post(`/User/walletTransfer`, {
  //       User_Transfer_id: UserIDFome,
  //       Amount: parseFloat(TransferAmount),
  //     });
  //     setResponsData(data);
  //     setreloadData(true);
  //   } catch (error) {
  //     enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
  //       variant: "error",
  //     });
  //     setResponsData(error);
  //     setreloadData(false);
  //   }
  // };

  const getPackassin = async (userid) => {
    try {
      const { data } = await api.get(`/User/getAssignPack`, {
        params: { userId: userid },
      });
      setpackageData(data.result);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
      setpackageData([]);
    }
  };

  useEffect(() => {
    if (selected?.userIdf) {
      getPackassin(selected?.userIdf);
    }
  }, [selected]);

  const createCustumar = async () => {
    try {
      const { data } = await api.post(`/User/AssignPackage`, packageData, {
        params: { userId: selected?.userIdf },
      });
      enqueueSnackbar(data?.message, { variant: "success" });
      setreloadData(true);
      setResponsData(data);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
      setreloadData(false);
    }
  };

  return (
    <Dialog
      open={!!selected}
      onClose={() => {
        setConfirmation(false);
        onClose();
        // window.location.reload();
      }}
    >
      {/* {JSON.stringify(selected)} */}
      <DialogTitle>Alot Packages To {selected?.UserTname}</DialogTitle>
      {/* {JSON.stringify(selected?.Ata)} */}
      <DialogContent>
        {/* {getPackassin(selected?.userIdf)} */}
        {!confirmation ? (
          <TableContainer component={Paper} sx={{ mt: 0, px: 0 }}>
            <Table>
              <TableRow>
                <TableCell sx={{ fontWeight: "900" }}>PackName</TableCell>
                <TableCell sx={{ fontWeight: "900" }}>Scr..</TableCell>
                <TableCell sx={{ fontWeight: "900" }}>Device Type</TableCell>
                <TableCell sx={{ fontWeight: "900" }}>Min Price</TableCell>
                <TableCell sx={{ fontWeight: "900" }}>Allotment Price</TableCell>
                <TableCell sx={{ fontWeight: "900" }}>MRP</TableCell>
              </TableRow>
              <TableBody>
                {packageData?.map(
                  ({ Id, packageName, screenCount, deviceType, priceMin, Mrp, sellPrice }) => (
                    <TableRow key={Id}>
                      <TableCell>{packageName}</TableCell>
                      <TableCell>{screenCount}</TableCell>
                      <TableCell>{deviceType}</TableCell>
                      <TableCell>{priceMin}</TableCell>
                      {/* <TableCell>{sellPrice}</TableCell> */}
                      <TableCell>
                        <TextField
                          label="Amount"
                          fullWidth
                          value={sellPrice}
                          onChange={(e) =>
                            setpackageData(
                              packageData.map((obj) => {
                                if (obj.Id === Id) {
                                  if (parseFloat(e.target.value) === 0) {
                                    console.log("Remove");
                                  } else if (parseFloat(e.target.value) <= parseFloat(priceMin)) {
                                    enqueueSnackbar("You can not sell lower than Minimum price", {
                                      variant: "error",
                                    });
                                  } else if (parseFloat(e.target.value) >= parseFloat(Mrp)) {
                                    enqueueSnackbar("You can not sell upper than MRP", {
                                      variant: "error",
                                    });
                                  } else if (e.target.value === "") {
                                    enqueueSnackbar("Enter price", {
                                      variant: "error",
                                    });
                                  } else if (!parseFloat(e.target.value)) {
                                    enqueueSnackbar("Enter only Numbers", {
                                      variant: "error",
                                    });
                                  } else {
                                    enqueueSnackbar("Correct Value", {
                                      variant: "success",
                                    });
                                  }
                                  return { ...obj, sellPrice: parseFloat(e.target.value) };
                                }
                                return obj;
                              })
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>{Mrp}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Stack spacing={2} mt={1}>
            {ResponsData.success && <Typography>Confirmation</Typography>}
            {!ResponsData.success && <Typography>Error</Typography>}
            <Typography>{ResponsData?.response?.data?.message}</Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} alignItems="center">
          <MDButton
            component={Button}
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => {
              if (!confirmation) {
                setConfirmation(true);
                createCustumar();
              } else {
                setConfirmation(false);
                onClose();
                if (reloadData === true) {
                  window.location.reload();
                }
              }
            }}
          >
            {!confirmation ? "Submit" : "Close"}
          </MDButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
