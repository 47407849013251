/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useState } from "react";
import MDButton from "components/MDButton";
import { api } from "util";
import { useSnackbar } from "notistack";

function Livestraming() {
  const { enqueueSnackbar } = useSnackbar();
  const [youtubeurl, setyoutubeurl] = useState("");
  const [facebookurl, setfacebookurl] = useState("");


  const Startstream = async (StreamType,Streamurl) => {
    try {
      const { data } = await api.post(`/livestrame/start`, {
        strametype: StreamType,
        strameurl: Streamurl
      });
      enqueueSnackbar(data?.message, { variant: "success" });
      window.location.reload();
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  const Stopstream = async (StreamType) => {
    try {
      const { data } = await api.post(`/livestrame/stop/`+StreamType);
      enqueueSnackbar(data?.message, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  // useEffect(() => {
  //   StateApi();
  // }, []);

  const onSubmityoutube = (e) => {
    Startstream("youtube",youtubeurl);
  };

  const onSubmitfacebook = (e) => {
    Startstream("facebook",facebookurl);
  };

  return (
    <DashboardLayout>
      <MDBox mt={5} mb={3} component="form" onSubmit={onSubmityoutube}>
        <Grid container spacing={1} sx={{ backgroundColor: "#fff" }} p={3}>
          <Grid item xs={12}>
            <Typography variant="h4" mb={3}>
              Youtube
            </Typography>
            <Stack spacing={3}>
              <TextField
                label="Youtube Live stream url"
                fullWidth
                value={youtubeurl}
                onChange={(e) => setyoutubeurl(e.target.value)}
              />
            </Stack>

            <Box mt={3} mb={2} display="flex" justifyContent="center">
              <MDButton
                component={Button}
                variant="gradient"
                color="info"
                size="large"
                type="submit"
              >
                Start Streaming
              </MDButton>
            </Box>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={5} mb={3} component="form" onSubmit={onSubmitfacebook}>
        <Grid container spacing={1} sx={{ backgroundColor: "#fff" }} p={3}>
          <Grid item xs={12}>
            <Typography variant="h4" mb={3}>
              Facebook
            </Typography>
            <Stack spacing={3}>
              <TextField
                label="Facebook Live stream url"
                fullWidth
                value={facebookurl}
                onChange={(e) => setfacebookurl(e.target.value)}
              />
            </Stack>

            <Box mt={3} mb={2} display="flex" justifyContent="center">
              <MDButton
                component={Button}
                variant="gradient"
                color="info"
                size="large"
                type="submit"
              >
                Start Streaming
              </MDButton>
            </Box>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={5} mb={3}>
        <Grid container spacing={1} sx={{ backgroundColor: "#fff" }} p={3}>
          <Grid item xs={12}>
            <Box mt={3} mb={2} display="flex" justifyContent="center">
              <MDButton
                component={Button}
                variant="gradient"
                color="info"
                size="large"
                type="submit"
                onClick={() => {
                  Stopstream("youtube")
                }}
                // onChange={}
              >
                Youtube Stream Stop
              </MDButton>

              <MDButton
                component={Button}
                variant="gradient"
                color="info"
                size="large"
                type="submit"
                onClick={() => {
                  Stopstream("facebook")
                }}
              >
                Facebook Stream Stop
              </MDButton>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Livestraming;
