/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const user = useSelector((state) => state.userReducer?.user);

  if (!user) {
    return <Navigate to="/authentication/sign-in" />;
  }

  return children;
};

// const PrivateRoute = ({ children }) => children;

export default PrivateRoute;
