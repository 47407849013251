/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Country } from "country-state-city";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { api } from "util";
import { useSnackbar } from "notistack";
import PackageModal from "./PackageModal";

function Billing() {
  const { enqueueSnackbar } = useSnackbar();
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("IN");
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [address, setaddress] = useState(null);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincodes, setPincodes] = useState([]);

  const [selectedPackage, setSelectedPackage] = useState(null);

  const StateApi = async () => {
    try {
      const { data } = await api.get(`/User/state_data`);

      setStates(data);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  const CityApi = async (CityName) => {
    try {
      const { data } = await api.get(`/User/city_data`, { params: { state_name: CityName } });

      setCities(data);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  const PinCodeApi = async (StateName, Citeyname) => {
    try {
      const { data } = await api.get(`/User/pincode_data`, {
        params: { state_name: StateName, city_name: Citeyname },
      });

      setPincodes(data);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    StateApi();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    // const regex = /\S+@\S+\.\S+/;

    // if (Number(mobileNumber) == NaN) {
    //   enqueueSnackbar("Plase enter 10 digit number", {
    //     variant: "error",
    //   });
    // }

    if (mobileNumber.length !== 10) {
      enqueueSnackbar("Plase enter 10 digit number", {
        variant: "error",
      });
      return;
    }

    // if (email !== "") {
    //   enqueueSnackbar("Plase enter your email id", {
    //     variant: "error",
    //   });
    // } else if (regex.test(email)) {
    //   enqueueSnackbar("Plase enter your email correct email adrres", {
    //     variant: "error",
    //   });
    //   return;
    // }

    if (!city) {
      enqueueSnackbar("Plase Select city", {
        variant: "error",
      });
      return;
    }

    if (!state) {
      enqueueSnackbar("Plase Select state", {
        variant: "error",
      });
      return;
    }

    if (!pincode) {
      enqueueSnackbar("Plase Select pincode", {
        variant: "error",
      });
      return;
    }

    setSelectedPackage({
      mobileNumber,
      email,
      name,
      gender,
      address,
      country,
      state,
      city,
      pincode,
    });
  };

  return (
    <DashboardLayout>
      <PackageModal selected={selectedPackage} onClose={() => setSelectedPackage(null)} />

      <MDBox mt={5} mb={3} component="form" onSubmit={onSubmit}>
        <Grid container spacing={1} sx={{ backgroundColor: "#fff" }} p={3}>
          <Grid item xs={12}>
            <Typography variant="h4" mb={3}>
              Add Member
            </Typography>
            <Stack spacing={3}>
              <TextField
                label="Mobile Number"
                fullWidth
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                placeholder="+91XXXXXXXXXX"
              />
              <TextField
                label="Email Address"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="abc@example.com"
              />
              <TextField
                label="Name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  value={gender}
                  label="Gender"
                  onChange={(e) => setGender(e.target.value)}
                  sx={{ height: 45 }}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>

              <TextField
                label="Address"
                fullWidth
                value={address}
                onChange={(e) => setaddress(e.target.value)}
              />

              <FormControl fullWidth disabled>
                <InputLabel>Country</InputLabel>
                <Select
                  value={country}
                  label="Country"
                  onChange={(e) => {
                    setCountry(e.target.value);
                    // setStates(State.getStatesOfCountry(e.target.value));
                  }}
                  sx={{ height: 45 }}
                >
                  {Country.getAllCountries()?.map(({ name: countryName, isoCode }) => (
                    <MenuItem key={isoCode} value={isoCode}>
                      {countryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth disabled={!country}>
                <InputLabel>State</InputLabel>
                <Select
                  value={state}
                  label="State"
                  onChange={(e) => {
                    setState(e.target.value);
                    setCity("");
                    setPincode("");
                    CityApi(e.target.value);
                  }}
                  sx={{ height: 45 }}
                >
                  {states?.map(({ Info_data: infoData }) => (
                    <MenuItem key={infoData} value={infoData}>
                      {infoData}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {state && (
                <FormControl fullWidth disabled={!state}>
                  <InputLabel>City</InputLabel>
                  <Select
                    value={city}
                    label="City"
                    onChange={(e) => {
                      setCity(e.target.value);
                      PinCodeApi(state, e.target.value);
                      setPincode("");
                    }}
                    sx={{ height: 45 }}
                  >
                    {cities?.map(({ Info_data: infoData }) => (
                      <MenuItem key={infoData} value={infoData}>
                        {infoData}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {city && (
                <FormControl fullWidth disabled={!city}>
                  <InputLabel>Pincode</InputLabel>
                  <Select
                    value={pincode}
                    label="Pin Code"
                    onChange={(e) => {
                      setPincode(e.target.value);
                    }}
                    sx={{ height: 45 }}
                  >
                    {pincodes?.map(({ Info_data: infoData }) => (
                      <MenuItem key={infoData} value={infoData}>
                        {infoData}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Stack>

            {pincode && (
              <Box mt={3} mb={2} display="flex" justifyContent="center">
                <MDButton
                  component={Button}
                  variant="gradient"
                  color="info"
                  size="small"
                  type="submit"
                >
                  Select Package
                </MDButton>
              </Box>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Billing;
