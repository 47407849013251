/* eslint-disable import/order */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import {
  Button,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import { api } from "util";
import { useSnackbar } from "notistack";
// import UserSearchTable from "./UserSearchTable";
import MDButton from "components/MDButton";
import AddTopicModeal from "layouts/Topic/AddTopicModal";
import EditTopicModeal from "layouts/Topic/EditTopicModal";
// import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";

// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import moment from "moment";

function Topic() {
  const { enqueueSnackbar } = useSnackbar();
  const [searchRes, SetsearchRes] = useState();
  const [username, setUsername] = useState();

  const [addingUser, setAddingUser] = useState();
  const [Topicedit, setTopicedit] = useState();
  // const [ClintUserID, setClintUserID] = useState();
  // const [PackAlot, setPackAlot] = useState();
  // const [getPackassinData, setgetPackassinData] = useState();
  // const [Walletdata, setWalletdata] = useState();

  const SarchTopic = async (topicName) => {
    try {
      if (topicName === "") {
        const { data } = await api.get(`/content/get/genre`);
        SetsearchRes(data.resalt);
      } else {
        const { data } = await api.get(`/content/get/genre`, { params: { topic: topicName } });
        SetsearchRes(data.resalt);
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  const DeleteTopic = async (DeleteId) => {
    try {
      const { data } = await api.delete(`/content/delete/genre/`+ DeleteId);
      enqueueSnackbar(data?.message, { variant: "success" });
      SarchTopic("");
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    SarchTopic("");
  }, []);

  return (
    <DashboardLayout>
      <AddTopicModeal selected={addingUser} onClose={() => setAddingUser(null)} />
      <EditTopicModeal selected={Topicedit} onClose={() => setTopicedit(null)} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Add Topic
                </MDTypography>
              </MDBox>
              <MDBox pt={3} pl={5}>
                <Stack direction="row" spacing={4}>
                  <TextField
                    label="Topic Name"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                      SarchTopic(e.target.value);
                    }}
                  />
                  <MDButton
                    component={Button}
                    variant="gradient"
                    color="info"
                    size="medium"
                    type="submit"
                    onClick={() => setAddingUser({})}
                  >
                    Topic
                  </MDButton>
                </Stack>
              </MDBox>
              <TableContainer component={Paper} sx={{ mt: 3, px: 3 }}>
                <Table>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "900" }}>Topic Name</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Created @</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Operations</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}></TableCell>
                  </TableRow>
                  <TableBody>
                    {searchRes?.map(
                      ({
                        genreId,
                        genreName,
                        createdAt,
                      }) => (
                        <TableRow key={genreId}>
                          <TableCell>{genreName}</TableCell>
                          <TableCell>
                            {moment(createdAt ).format("YYYY-MM-DD HH:mm:ss")}
                          </TableCell>
                          <TableCell>
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              type="submit"
                              onClick={() => {
                                DeleteTopic(genreId);
                              }}
                            >
                              Delete Topic
                            </MDButton>
                          </TableCell>
                          <TableCell>
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              type="submit"
                              onClick={() => {
                                setTopicedit({ Topicname: genreName ,topicId :genreId});
                              }}
                            >
                              Edit Topic Name
                            </MDButton>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <UserSearchTable users={searchRes} /> */}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Topic;
