/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { api } from "util";
import { useSnackbar } from "notistack";
// import { useNavigate } from "react-router-dom";
// import MDBox from "components/MDBox";

export default function EditTopicModeal({ selected, onClose }) {
  // const [package1, setPackage1] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [topicName, settopicName] = useState("");
  const [ResponsData, setResponsData] = useState("");

  const [confirmation, setConfirmation] = useState(false);
  const [reloadData, setreloadData] = useState(false);

  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);
  // window.location.reload();
  // const navigate = useNavigate();

  const EditTopicreq = async (toopicId,topicNameUp) => {
    try {
      const { data } = await api.patch(`/content/update/genre`, {
        topicId: toopicId,
        topicName: topicNameUp
      });
      setResponsData(data);
      setreloadData(true);
    } catch (error) {
      setResponsData(error?.response?.data);
    }
  };

  useEffect(() => {
    setreloadData(false);
  }, []);

  return (
    <Dialog
      open={!!selected}
      onClose={() => {
        setConfirmation(false);
        onClose();
      }}
      fullWidth
    >
      <DialogTitle>Edit Topic :- {selected?.Topicname}</DialogTitle>
      <DialogContent>
        {!confirmation ? (
          <Stack spacing={2} mt={1}>
            <TextField
              label="Topic Name"
              fullWidth
              value={topicName}
              onChange={(e) => settopicName(e.target.value)}
            />
          </Stack>
        ) : (
          <Stack spacing={2} mt={1}>
            {ResponsData.status && <Typography>Confirmation</Typography>}
            {!ResponsData.status && <Typography>Error</Typography>}
            <Typography>{ResponsData?.message ?? "Something went wrong"}</Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} alignItems="center">
          <MDButton
            component={Button}
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => {
              if (!confirmation) {
                setConfirmation(true);
                EditTopicreq(selected.topicId,topicName);
              } else {
                setConfirmation(false);
                onClose();
                if (reloadData === true) {
                  window.location.reload();
                }
              }
            }}
          >
            {!confirmation ? "Submit" : "Close"}
          </MDButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
