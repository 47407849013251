/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Stack,
  Select,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { api } from "util";
import { useSnackbar } from "notistack";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { array } from "prop-types";
// import { useNavigate } from "react-router-dom";
// import MDBox from "components/MDBox";

export default function AddEpisodeModal({ selected, onClose }) {
  const imginputRef = useRef();
  const videoinputRef = useRef();
  // const [package1, setPackage1] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [titlevar, settitle] = useState(null);
  const [articalvar, setartical] = useState(null);
  const [descriptionvar, setdescription] = useState(null);
  const [contentvar, setcontentype] = useState("");
  const [sarchvar, setsarchvar] = useState("");
  const [topicvar, settopicvar] = useState(null);
  const [statevar, setstatevar] = useState(null);

  const [ResponsData, setResponsData] = useState("");
  const [checkedValues, setCheckedValues] = useState([]);
  const [checkedChannel, setCheckedChannel] = useState([]);

  const [confirmation, setConfirmation] = useState(false);
  const [reloadData, setreloadData] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [ImgId, setImgId] = useState("")
  const [VideoId, setVideoId] = useState("")

  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);
  // window.location.reload();
  // const navigate = useNavigate();

  const ADDNews = async () => {
    try {
      let contInserdata = {}
      const sarectagarra = sarchvar.split(" ");
      sarectagarra = array(sarectagarra)
      if (contentvar === "newsText"){
        contInserdata = {
          title: titlevar,
          description:descriptionvar,
          imgId:ImgId,
          article:articalvar,
          contantType:contentvar,
          searchTag:sarectagarra,
          genresId:checkedValues,
          channelId:checkedChannel,
        }
      } else if (contentvar === "newsVideo"){
        contInserdata = {
          title: titlevar,
          description:descriptionvar,
          imgId:ImgId,
          videoId:VideoId,
          article:articalvar,
          contantType:contentvar,
          searchTag:sarectagarra,
          genresId:checkedValues,
          channelId:checkedChannel,
        }
      }
      const { data } = await api.post(`/content/add/news`, contInserdata);
      console.log(data)
      setResponsData(data);
      setreloadData(true);
    } catch (error) {
      setResponsData(error?.response?.data);
    }
  };

  const GetTopic = async (topicName) => {
    try {
      const { data } = await api.get(`/content/get/genre?showtype=Dropdown`);
      settopicvar(data.resalt);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  const GetChannel = async (topicName) => {
    try {
      const { data } = await api.get(`/content/get/channel?showtype=Dropdown`);
      setstatevar(data.resalt);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setreloadData(false);
    GetTopic();
    GetChannel();
  }, []);

  const UploadImage = async (filedata) => {
    try {
      const { data } = await api.post(`/upload/img`, filedata,{headers: {'Content-Type': 'multipart/form-data'}});
      console.log(data?.resalt)
      setImgId(data?.resalt);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  const UploadVideo = async (filedata) => {
    try {
      const { data } = await api.post(`/upload/video`, filedata,{headers: {'Content-Type': 'multipart/form-data'}});
      console.log(data?.resalt)
      setVideoId(data?.resalt);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };
  
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const currentIndex = checkedValues.indexOf(value);
    const newCheckedValues = [...checkedValues];
    
    if (currentIndex === -1) {
      newCheckedValues.push(value);
    } else {
      newCheckedValues.splice(currentIndex, 1);
    }

    setCheckedValues(newCheckedValues);
  }

  const handleCheckboxChangeChannel = (event) => {
    const value = event.target.value;
    const currentIndexChannel = checkedChannel.indexOf(value);
    const newCheckedChannel = [...checkedChannel];
    
    if (currentIndexChannel === -1) {
      newCheckedChannel.push(value);
    } else {
      newCheckedChannel.splice(currentIndexChannel, 1);
    }
    
    setCheckedChannel(newCheckedChannel);
  }
  

  const handleFileUpload = (e) => {
    if(!e.target.files.length) return;
    const file = e.target.files[0];
    setSelectedFile(file);
    
    const formData = new FormData();
    formData.append('image', file);
    setImgId("uploding Img")
    UploadImage(formData)
  }

  const handleVideoUpload = (e) => {
    if(!e.target.files.length) return;
    const file = e.target.files[0];
    setSelectedVideo(file);
    
    const formData = new FormData();
    formData.append('video', file);
    setVideoId("uploding Video")
    UploadVideo(formData)
  }

  return (
    <Dialog
      open={!!selected}
      onClose={() => {
        setConfirmation(false);
        onClose();
      }}
      fullWidth
    >
      <DialogTitle>Add Episode</DialogTitle>
      <DialogContent>
        {!confirmation ? (
          <Stack spacing={2} mt={1}>
              <TextField
                label="Title"
                fullWidth
                value={titlevar}
                onChange={(e) => settitle(e.target.value)}
              />
              <TextField
                label="Episode Title"
                fullWidth
                value={titlevar}
                onChange={(e) => settitle(e.target.value)}
              />
              <TextField
                label="Episode Number"
                fullWidth
                value={titlevar}
                onChange={(e) => settitle(e.target.value)}
              />
              <h4>Description</h4>
              <TextareaAutosize fullWidth minRows={6} maxRows={6} value={descriptionvar} onChange={(e) => setdescription(e.target.value)} />
              <h4>Search Tags</h4>
              <TextareaAutosize fullWidth minRows={10} maxRows={15} value={sarchvar} onChange={(e) => setsarchvar(e.target.value)} />
            <input ref={imginputRef} type='file' onChange={handleFileUpload} hidden />
            <Button onClick={() => imginputRef.current?.click()}>{selectedFile ? selectedFile.name : 'Upload Episode IMG'}</Button>
            <input ref={videoinputRef} type='file' onChange={handleVideoUpload} hidden />
            <Button onClick={() => videoinputRef.current?.click()}>{selectedVideo ? selectedVideo.name : 'Upload Episode Video'}</Button>

          </Stack>
        ) : (
          <Stack spacing={2} mt={1}>
            {ResponsData === "" && <Typography>Loding...</Typography>}
            {ResponsData && <Typography>Confirmation</Typography>}
            {ResponsData && <Typography>{ResponsData.message}</Typography>}
            {ResponsData?.status === false && <Typography>Error</Typography>}
            {ResponsData?.status === false && <Typography>{ResponsData.message}</Typography>}
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} alignItems="center">
          {ImgId === "uploding" && (<MDButton
            component={Button}
            variant="gradient"
            color="info"
            size="medium"
          > Uploading Img... </MDButton>)}

          {VideoId === "uploding" && (<MDButton
            component={Button}
            variant="gradient"
            color="info"
            size="medium"
          > Uploading Video... </MDButton>)}

          {ImgId  && (<MDButton
            component={Button}
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => {
              if (!confirmation) {
                setConfirmation(true);
                ADDNews();
              } else {
                setConfirmation(false);
                onClose();
                if (reloadData === true) {
                  window.location.reload();
                }
              }
            }}
          >
            {!confirmation ? "Submit" : "Close"}
          </MDButton>)}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
