/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
// import Search from "layouts/search";
import Billing from "layouts/billing";

import Topic from "layouts/Topic";
import States from "layouts/States";

// @mui icons
import Icon from "@mui/material/Icon";
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import PersonSearchIcon from "@mui/icons-material/PersonSearch";
// import ContactsIcon from "@mui/icons-material/Contacts";
// import TvOffIcon from "@mui/icons-material/TvOff";
// import TvIcon from "@mui/icons-material/Tv";
// import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
// import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ChangePassword from "layouts/changepassword";
// import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
// import QueueIcon from "@mui/icons-material/Queue";
// import TopicIcon from "@mui/icons-material/Topic";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import CastIcon from '@mui/icons-material/Cast';
// import TheatersIcon from "@mui/icons-material/Theaters";

// import Subscription from "layouts/subscription";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },
  // {
  //   type: "collapse",
  //   name: "Topic",
  //   key: "addtopic",
  //   icon: <TopicIcon />,
  //   route: "/addtopic",
  //   component: <Topic />,
  //   isAdminOnly: false,
  // },
  // {
  //   type: "collapse",
  //   name: "States",
  //   key: "statesmangement",
  //   icon: <QueueIcon />,
  //   route: "/statesmangement",
  //   component: <States />,
  //   isAdminOnly: false,
  // },
  {
    type: "collapse",
    name: "News",
    key: "news",
    icon: <NewspaperIcon />,
    route: "/news",
    component: <Billing />,
    isAdminOnly: false,
  },
  // {
  //   type: "collapse",
  //   name: "show",
  //   key: "show",
  //   icon: <TheatersIcon />,
  //   route: "/show",
  //   component: <Billing />,
  //   isAdminOnly: false,
  // },
  // {
  //   type: "collapse",
  //   name: "Page Management",
  //   key: "dashmanagement",
  //   icon: <DashboardCustomizeIcon />,
  //   route: "/dashmanagement",
  //   component: <Billing />,
  //   isAdminOnly: false,
  // },
  // {
  //   type: "collapse",
  //   name: "User Management",
  //   key: "wallet-transfer",
  //   icon: <ContactsIcon />,
  //   route: "/wallet-transfer",
  //   component: <WalletTransfer />,
  // },
  {
    type: "collapse",
    name: "Change Password",
    key: "change-password",
    icon: <ManageAccountsIcon />,
    route: "/change-password",
    component: <ChangePassword />,
  },
  {
    type: "collapse",
    name: "Live Strame",
    key: "live-strame",
    icon: <CastIcon />,
    route: "/live-strame",
    component: <CastIcon />,
  },
];

export default routes;
