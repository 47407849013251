/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useState } from "react";
import MDButton from "components/MDButton";
import { api } from "util";
import { useSnackbar } from "notistack";

function ChangePassword() {
  const { enqueueSnackbar } = useSnackbar();
  const [currentPassword, setcurrentPassword] = useState("");
  const [Password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const createCustumar = async () => {
    try {
      const { data } = await api.post(`/user/changePassword`, {
        oldPassword: currentPassword,
        newPassword: Password,
        samePassword: confirmPassword,
      });
      enqueueSnackbar(data?.message, { variant: "success" });
      window.location.reload();
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  // useEffect(() => {
  //   StateApi();
  // }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (currentPassword === "") {
      enqueueSnackbar("Please enter old password", {
        variant: "error",
      });
      return;
    }
    if (Password !== confirmPassword) {
      enqueueSnackbar("Password Not Match", {
        variant: "error",
      });
      return;
    }
    createCustumar();
  };

  return (
    <DashboardLayout>
      <MDBox mt={5} mb={3} component="form" onSubmit={onSubmit}>
        <Grid container spacing={1} sx={{ backgroundColor: "#fff" }} p={3}>
          <Grid item xs={12}>
            <Typography variant="h4" mb={3}>
              Change Password
            </Typography>
            <Stack spacing={3}>
              <TextField
                label="Old Password"
                fullWidth
                value={currentPassword}
                onChange={(e) => setcurrentPassword(e.target.value)}
              />
              <TextField
                label="Create New Password"
                fullWidth
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                label="Confirm New Password"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setconfirmPassword(e.target.value)}
              />
            </Stack>

            <Box mt={3} mb={2} display="flex" justifyContent="center">
              <MDButton
                component={Button}
                variant="gradient"
                color="info"
                size="large"
                type="submit"
              >
                Change Password
              </MDButton>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ChangePassword;
