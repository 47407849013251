import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./user/userSlice";
import drawerReducer from "./menu/drawerSlice";

let user = localStorage.getItem("user");
if (user) user = JSON.parse(user);

const preloadedState = {
  userReducer: { user },
};

export default configureStore({
  reducer: {
    userReducer,
    drawerReducer,
  },
  preloadedState,
});
