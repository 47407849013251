/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import { api } from "util";
import { useSnackbar } from "notistack";
import ActiveTodayTable from "./ActiveTodayTable";
// import DataTable from "examples/Tables/DataTable";

// Data
// import search from "./data/searchData";

function ActiveToday() {
  const { enqueueSnackbar } = useSnackbar();

  const [employees, setEmployees] = useState(null);

  const [page, setPage] = useState(1);

  const getEmployees = async (page) => {
    try {
      const { data } = await api.get(`/subscription/report/Active`, {
        params: {
          page,
        },
      });

      if (data.result.length !== 0) {
        setEmployees(null);
        setEmployees(data.result);
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    getEmployees(page);
  }, [page]);

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Active Today
                </MDTypography>
              </MDBox>

              <ActiveTodayTable
                users={employees}
                page={page}
                nextPage={() => setPage((pre) => pre + 1)}
                prevPage={() => setPage((pre) => (pre > 1 ? pre - 1 : pre))}
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ActiveToday;
