/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import { api } from "util";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

function Dashboard() {
  const [dataGet, setData] = useState();
  // const [Wlammount, setWlammount] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const DashboardReq = async () => {
    try {
      const { data } = await api.get(`/User/analysis`);

      setData(data);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  // const DashboardwReq = async () => {
  //   try {
  //     const { data } = await api.get(`/User/wallet`);

  //     setWlammount(data?.msg);
  //   } catch (error) {
  //     enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
  //       variant: "error",
  //     });
  //   }
  // };

  useEffect(() => {
    DashboardReq();
  }, []);

  return (
    <DashboardLayout>
      {/* {JSON.stringify(dataGet)} */}
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <DefaultInfoCard
                    icon="subscriptions"
                    title="Total Text News Post"
                    value={dataGet?.totalSubscribers}
                    color="blue"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <DefaultInfoCard
                    icon="computer"
                    title="Total Video News Post"
                    value={dataGet?.activeSubscribers}
                    color="green"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <DefaultInfoCard
                    icon="computer"
                    title="Total Show"
                    value={dataGet?.expiredSubscribed}
                    color="red"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <DefaultInfoCard
                    icon="arrow_circle_left_outlilned"
                    title="Total Episode"
                    value="0"
                    color="red"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
