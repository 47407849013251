/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import { Box, Stack, Typography } from "@mui/material";

function DefaultInfoCard({ color, icon, title, value }) {
  return (
    <Card>
      <Stack direction="row" p={2} justifyContent="space-between">
        <Stack spacing={1}>
          <Typography fontSize={12} fontWeight="medium">
            {title}
          </Typography>
          <Typography fontSize={14} fontWeight="medium">
            {value}
          </Typography>
        </Stack>

        <Box>
          <Icon fontSize="large" sx={{ mt: 1, color }}>
            {icon}
          </Icon>
        </Box>
      </Stack>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
  color: "info",
  value: "",
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DefaultInfoCard;
