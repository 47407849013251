import React from "react";
import { Routes, Route } from "react-router-dom";
// import useUser from "../hooks/useUser";
// import PopularCard from "../views/components/skeleton/PopularCard";
import Dashboard from "layouts/dashboard";
// import Search from "layouts/search";
import SignIn from "layouts/authentication/sign-in";
import ResetPassword from "layouts/authentication/reset-password/cover";
// import AddEmployee from "layouts/addEmployee";
import WalletTransfer from "layouts/walletTransfer";
import Subscription from "layouts/subscription";
import Topic from "layouts/Topic";
import States from "layouts/States";
import WallerTransaction from "layouts/report/WallerTransaction";
import MemberTransaction from "layouts/report/MemberTransaction";
import DeactivateToday from "layouts/report/DeactivateToday";
import ActiveToday from "layouts/report/ActiveToday";
import ChangePassword from "layouts/changepassword";
import Livestraming from "layouts/Livestraming";
import News from "layouts/News";
import Show from "layouts/Show";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

export default function Routing() {
  // const { isLoading } = useUser();

  // if (isLoading) {
  //   return <PopularCard />;
  // }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/authentication/sign-in"
        element={
          <PublicRoute>
            <SignIn />
          </PublicRoute>
        }
      />
      <Route
        path="/authentication/reset-password"
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />

      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path="/addtopic"
        element={
          <PrivateRoute>
            <Topic />
          </PrivateRoute>
        }
      />
      
      <Route
        path="/live-strame"
        element={
          <Livestraming>
            <Topic />
          </Livestraming>
        }
      />

      <Route
        path="/statesmangement"
        element={
          <PrivateRoute>
            <States />
          </PrivateRoute>
        }
      />
      
      <Route
        path="/news"
        element={
          <PrivateRoute>
            <News />
          </PrivateRoute>
        }
      />

      <Route
        path="/show"
        element={
          <PrivateRoute>
            <Show />
          </PrivateRoute>
        }
      />

      <Route
        path="/wallet-transfer"
        element={
          <PrivateRoute>
            <WalletTransfer />
          </PrivateRoute>
        }
      />

      <Route
        path="/subscription"
        element={
          <PrivateRoute>
            <Subscription />
          </PrivateRoute>
        }
      />

      <Route
        path="/report/active-today"
        element={
          <PrivateRoute>
            <ActiveToday />
          </PrivateRoute>
        }
      />

      <Route
        path="/report/deactivate-today"
        element={
          <PrivateRoute>
            <DeactivateToday />
          </PrivateRoute>
        }
      />

      <Route
        path="/report/wallet-transaction"
        element={
          <PrivateRoute>
            <WallerTransaction />
          </PrivateRoute>
        }
      />

      <Route
        path="/report/member-transactione"
        element={
          <PrivateRoute>
            <MemberTransaction />
          </PrivateRoute>
        }
      />

      <Route
        path="/change-password"
        element={
          <PrivateRoute>
            <ChangePassword />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
