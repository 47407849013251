/* eslint-disable import/order */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import {
  Button,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import { api } from "util";
import { useSnackbar } from "notistack";
// import UserSearchTable from "./UserSearchTable";
import MDButton from "components/MDButton";
import AddUserModeal from "layouts/walletTransfer/AddUserModal";
import WalletMoneyTransfer from "layouts/walletTransfer/WalletMoneyTransfer";
import PackageAssign from "layouts/walletTransfer/PackageAssign";
// import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import moment from "moment";

function WalletTransfer() {
  const { enqueueSnackbar } = useSnackbar();
  const [searchRes, SetsearchRes] = useState();
  const [username, setUsername] = useState();

  const [addingUser, setAddingUser] = useState();
  const [ClintUserID, setClintUserID] = useState();
  const [PackAlot, setPackAlot] = useState();
  // const [getPackassinData, setgetPackassinData] = useState();
  // const [Walletdata, setWalletdata] = useState();

  const SarchClint = async (userName) => {
    try {
      if (userName === "") {
        const { data } = await api.get(`/User/getUser`);
        SetsearchRes(data.resalt);
      } else {
        const { data } = await api.get(`/User/getUser`, { params: { UserName: userName } });
        SetsearchRes(data.resalt);
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    SarchClint("");
  }, []);

  return (
    <DashboardLayout>
      <AddUserModeal selected={addingUser} onClose={() => setAddingUser(null)} />
      <WalletMoneyTransfer selected={ClintUserID} onClose={() => setClintUserID(null)} />
      <PackageAssign selected={PackAlot} onClose={() => setPackAlot(null)} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  User Management
                </MDTypography>
              </MDBox>
              <MDBox pt={3} pl={5}>
                <Stack direction="row" spacing={4}>
                  <TextField
                    label="Username"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                      SarchClint(e.target.value);
                    }}
                  />
                  <MDButton
                    component={Button}
                    variant="gradient"
                    color="info"
                    size="medium"
                    type="submit"
                    onClick={() => setAddingUser({})}
                  >
                    Add User
                  </MDButton>
                </Stack>
              </MDBox>
              <TableContainer component={Paper} sx={{ mt: 3, px: 3 }}>
                <Table>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "900" }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Username</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>User Type</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Company Name</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>User Created Under</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Created On</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Operations</TableCell>
                  </TableRow>
                  <TableBody>
                    {searchRes?.map(
                      ({
                        Id,
                        Name,
                        Username,
                        UserType,
                        CompneyName,
                        UserUnder,
                        CreatedAt,
                        // IsAresAssign,
                        IsWalletTransfer,
                        IsPackAssign,
                      }) => (
                        <TableRow key={Id}>
                          <TableCell>{Name}</TableCell>
                          <TableCell>{Username}</TableCell>
                          <TableCell>{UserType}</TableCell>
                          <TableCell>{CompneyName}</TableCell>
                          <TableCell>{UserUnder}</TableCell>
                          <TableCell>
                            {moment(CreatedAt / 1000000).format("YYYY-MM-DD HH:mm:ss")}
                          </TableCell>
                          <TableCell>
                            {IsWalletTransfer && (
                              <MDButton
                                component={Button}
                                variant="gradient"
                                color="info"
                                size="small"
                                type="submit"
                                onClick={() => setClintUserID({ Tname: Username, UserId: Id })}
                              >
                                Money Transfer
                              </MDButton>
                            )}
                          </TableCell>
                          {/* {IsAresAssign && (
                            <TableCell>
                              <MDButton color="info" size="small" ml={10} onClick={() => {}}>
                                Alot Area
                              </MDButton>
                            </TableCell>
                          )} */}
                          {IsPackAssign && (
                            <TableCell>
                              <MDButton
                                variant="gradient"
                                color="info"
                                size="small"
                                type="submit"
                                onClick={() => {
                                  setPackAlot({ UserTname: Username, userIdf: Id });
                                  // getPackassin(Username, Id);
                                  // setPackAlot({ Tname: Username, userId: Id });
                                }}
                              >
                                Alot Package
                              </MDButton>
                            </TableCell>
                          )}
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <UserSearchTable users={searchRes} /> */}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default WalletTransfer;
