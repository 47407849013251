/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  // setMiniSidenav,
  // setTransparentSidenav,
  // setWhiteSidenav,
} from "context";
import { userLogout } from "redux/user/userSlice";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { api } from "util";
import { withStyles } from "@material-ui/core/styles";

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const Collapsible = ({ name, children }) => {
  const [show, setShow] = useState(false);

  return (
    <div style={{ backgroundColor: show ? "#191919" : "transparent" }}>
      <Button onClick={() => setShow((pre) => !pre)} role="button">
        {show ? (
          <ExpandLess sx={{ color: "#fff" }} fontSize="large" />
        ) : (
          <ExpandMore sx={{ color: "#fff" }} />
        )}
        <MDTypography
          sx={{ color: "#fff", fontSize: 14, textTransform: "none", fontWeight: "400", ml: 1 }}
        >
          {name}
        </MDTypography>
      </Button>

      {show && <div style={{ paddingLeft: 10 }}>{children}</div>}
      <Divider light />
    </div>
  );
};

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const navigate = useNavigate();
  const dispatchAction = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.userReducer?.user);

  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [LogoutStatus, setLogoutStatus] = useState(false);

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const LogoutReq = async () => {
    try {
      const { data } = await api.get(`/auth/logout`);
      if (data.success === true) {
        // setLogoutStatus(true);
        enqueueSnackbar("Successfully logout", { variant: "success" });
        dispatchAction(userLogout());
        navigate("/");
      }
      return;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      if (window.innerWidth >= 1200) setDrawerOpen(true);
      // setMiniSidenav(dispatch, false);
      // setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      // setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const routesBody = ({ type, name, icon, title, noCollapse, key, href, route, collapse }) => {
    let returnValue;

    if (type === "menu") {
      returnValue = <Collapsible name={name}>{collapse.map((c) => routesBody(c))}</Collapsible>;
    } else if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  };

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(routesBody);

  return (
    <>
      <Box
        sx={(theme) => ({
          display: "none",

          [theme.breakpoints.down("xl")]: {
            display: !drawerOpen ? "block" : "none",
            p: 2,
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 10,
          },
        })}
      >
        <IconButton
          onClick={() => {
            setDrawerOpen((pre) => !pre);
          }}
          sx={{ color: "#fff", backgroundColor: colors.gradients.dark.main, borderRadius: 1 }}
        >
          <Icon fontSize="medium">menu</Icon>
        </IconButton>
      </Box>
      <Box sx={{ display: drawerOpen ? "block" : "none" }}>
        <SidenavRoot
          {...rest}
          variant="permanent"
          ownerState={{
            transparentSidenav,
            whiteSidenav,
            darkMode,
          }}
        >
          <MDBox pt={3} pb={1} px={4} textAlign="center">
            <MDBox display="flex" alignItems="center">
              <IconButton
                onClick={() => {
                  setDrawerOpen((pre) => !pre);
                }}
                sx={(theme) => ({
                  borderRadius: 1,
                  [theme.breakpoints.up("xl")]: {
                    display: "none",
                  },
                })}
              >
                <Icon fontSize="medium" sx={{ color: "#fff" }}>
                  menu
                </Icon>
              </IconButton>

              <MDBox
                width={!brandName && "100%"}
                sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  ml={5}
                >
                  {/* <img
                    src="https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg"
                    alt=""
                    width={50}
                    height={50}
                  /> */}
                  <MDTypography
                    component="h6"
                    variant="button"
                    fontWeight="medium"
                    color={textColor}
                  >
                    {brandName}
                  </MDTypography>
                </Stack>
              </MDBox>
            </MDBox>
          </MDBox>
          <Divider
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
          <List>{renderRoutes}</List>
          <SidenavCollapse
            name="Logout"
            icon={<Icon>logout</Icon>}
            onClick={async () => {
              LogoutReq();
              // if (e.target.cancelable === true) {
              //   dispatchAction(userLogout());
              //   navigate("/");
              // }
            }}
          />
          <Box m={1} pt={0}>
            {user?.userType === "Jv" && (
              <WhiteTextTypography sx={{ fontSize: "1.5em !important" }}>
                Joint Venture
              </WhiteTextTypography>
            )}
            {user?.userType !== "Jv" && (
              <WhiteTextTypography sx={{ fontSize: "1.5em !important" }}>
                {user?.userType}
              </WhiteTextTypography>
            )}
          </Box>
          {/* <SidenavCollapse name="JV" /> */}
        </SidenavRoot>
      </Box>
    </>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
