/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { api } from "util";
import { useSnackbar } from "notistack";
// import { useNavigate } from "react-router-dom";
// import MDBox from "components/MDBox";

export default function WalletMoneyTransfer({ selected, onClose }) {
  // const [package1, setPackage1] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  // const [UserName, setUserName] = useState("");

  const [confirmation, setConfirmation] = useState(false);
  // const navigate = useNavigate();

  // const [UserIDFome,setUserID] = useState(false)
  const [TransferAmountD, setTransferAmountD] = useState(0);
  const [transferType, settransferType] = useState(0);

  const [ResponsData, setResponsData] = useState("");
  const [reloadData, setreloadData] = useState(false);

  const TansferAmount = async (UserIDFome, TransferAmount) => {
    try {
      const { data } = await api.post(`/User/walletTransfer`, {
        User_Transfer_id: UserIDFome,
        Amount: parseFloat(TransferAmount),
        TransferType: transferType,
      });
      setResponsData(data);
      setreloadData(true);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
      setResponsData(error);
      setreloadData(false);
    }
  };

  return (
    <Dialog
      open={!!selected}
      onClose={() => {
        setConfirmation(false);
        onClose();
        // window.location.reload();
      }}
      fullWidth
    >
      <DialogTitle>Money Transferred To {selected?.Tname}</DialogTitle>
      <DialogContent>
        {!confirmation ? (
          <Stack spacing={2} mt={1}>
            <TextField
              label="Amount"
              fullWidth
              value={TransferAmountD}
              onChange={(e) => setTransferAmountD(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel>Transfer Type</InputLabel>
              <Select
                value={transferType}
                label="moneyTransfer"
                onChange={(e) => settransferType(e.target.value)}
                sx={{ height: 45 }}
              >
                <MenuItem value="CASH">CASH</MenuItem>
                <MenuItem value="DD">DD</MenuItem>
                <MenuItem value="RTGS">RTGS</MenuItem>
                <MenuItem value="IMPS">IMPS</MenuItem>
                <MenuItem value="NEFT">NEFT</MenuItem>
                <MenuItem value="UPI">UPI</MenuItem>
                <MenuItem value="CHEQUES">CHEQUES</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        ) : (
          <Stack spacing={2} mt={1}>
            {ResponsData.success && <Typography>Confirmation</Typography>}
            {!ResponsData.success && <Typography>Error</Typography>}
            <Typography>{ResponsData?.response?.data?.message}</Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} alignItems="center">
          <MDButton
            component={Button}
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => {
              if (!confirmation) {
                setConfirmation(true);
                TansferAmount(selected?.UserId, TransferAmountD);
              } else {
                setConfirmation(false);
                onClose();
                if (reloadData === true) {
                  window.location.reload();
                }
              }
            }}
          >
            {!confirmation ? "Submit" : "Close"}
          </MDButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
