/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import MDBox from "components/MDBox";

export default function ViewStateIconModal({ selected, onClose }) {
  // const [package1, setPackage1] = useState("");

  const [confirmation, setConfirmation] = useState(false);

  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);
  // window.location.reload();
  // const navigate = useNavigate();

  useEffect(() => {
  }, []);

  console.log(selected)

  return (
    <Dialog
      open={!!selected}
      onClose={() => {
        setConfirmation(false);
        onClose();
      }}
      fullWidth
    >
      <DialogTitle>Icons {selected?.ChannelName}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={1}>
          <h4>1440X810</h4>
          <img src={selected?.BIcon?.Size_1440_810} alt="" />
          <h4>1024_576</h4>
          <img src={selected?.BIcon?.Size_1024_576} alt="" />
          <h4>180x180</h4>
          <img src={selected?.Incon?.Size_180_180} alt="" />
          <h4>120x120</h4>
          <img src={selected?.Incon?.Size_120_120} alt="" />
          <h4>167x167</h4>
          <img src={selected?.Incon?.Size_167_167} alt="" />
          <h4>152x152</h4>
          <img src={selected?.Incon?.Size_152_152} alt="" />

          </Stack>
      </DialogContent>
    </Dialog>
  );
}
