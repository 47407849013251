/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Stack } from "@mui/material";
import MDButton from "components/MDButton";
import moment from "moment";

export default function WallerTransactionTable({ users, page, nextPage, prevPage }) {
  return (
    <TableContainer sx={{ mt: 3, px: 3 }}>
      <Table>
        <TableRow>
          <TableCell sx={{ fontWeight: "900" }}>Transaction ID</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Sender</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Current Balance of Sender</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Receiver</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Amount Received</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Transaction Type</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Status</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Created on</TableCell>
        </TableRow>
        <TableBody>
          {users?.map(
            ({
              TransactionID,
              Sender,
              SenderAmount,
              Reciver,
              ReciveAmount,
              TransactionType,
              TransactionStatus,
              Createdon,
            }) => (
              <TableRow key={TransactionID}>
                <TableCell>{TransactionID}</TableCell>
                <TableCell>{Sender}</TableCell>
                <TableCell>
                  {"\u20b9"} {SenderAmount}
                </TableCell>
                <TableCell>{Reciver}</TableCell>
                <TableCell>
                  {"\u20b9"} {ReciveAmount}
                </TableCell>
                <TableCell>{TransactionType}</TableCell>
                <TableCell>{TransactionStatus}</TableCell>
                <TableCell>{moment(Createdon / 1000000).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>

      <Stack direction="row" my={4} justifyContent="flex-end" spacing={6} px={2}>
        {page > 1 && (
          <MDButton
            color="info"
            size="small"
            onClick={() => {
              prevPage();
            }}
          >
            Prev
          </MDButton>
        )}
        <MDButton
          color="info"
          size="small"
          onClick={() => {
            nextPage();
          }}
        >
          Next
        </MDButton>
      </Stack>
    </TableContainer>
  );
}
