/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Stack } from "@mui/material";
import MDButton from "components/MDButton";
import moment from "moment";

export default function DeactivateTodayTable({ users, page, nextPage, prevPage }) {
  return (
    <TableContainer sx={{ mt: 3, px: 3 }}>
      <Table>
        <TableRow>
          <TableCell sx={{ fontWeight: "900" }}>Subscriber Name</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Mobile Number</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>City</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Pin Code</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Pack Name</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Screen Count</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Device Type</TableCell>
          <TableCell sx={{ fontWeight: "900" }}>Date of Expiry</TableCell>
        </TableRow>
        <TableBody>
          {users?.map(
            ({
              Name,
              Phone_number,
              City,
              Pincode,
              Plan_name,
              Screen_count,
              Device_type,
              Expire_at,
            }) => (
              <TableRow key="TransactionID">
                <TableCell>{Name}</TableCell>
                <TableCell>{Phone_number}</TableCell>
                <TableCell>{City}</TableCell>
                <TableCell>{Pincode}</TableCell>
                <TableCell>{Plan_name}</TableCell>
                <TableCell>{Screen_count}</TableCell>
                <TableCell>{Device_type}</TableCell>
                <TableCell>{moment(Expire_at * 1000).format("YYYY-MM-DD")}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>

      <Stack direction="row" my={4} justifyContent="flex-end" spacing={6} px={2}>
        {page > 1 && (
          <MDButton
            color="info"
            size="small"
            onClick={() => {
              prevPage();
            }}
          >
            Prev
          </MDButton>
        )}
        <MDButton
          color="info"
          size="small"
          onClick={() => {
            nextPage();
          }}
        >
          Next
        </MDButton>
      </Stack>
    </TableContainer>
  );
}
