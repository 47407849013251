/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { Box, Paper, Stack, TextField, styled } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

const data = [
  {
    heading: "Heading",
    submenu: [
      { title: "Accordion 1", type: "accordian", text: "Text", status: "status" },
      { title: "Accordion 2", type: "simple", text: "Text", status: "status" },
      { title: "Accordion 3", type: "accordian", text: "Text", status: "status" },
    ],
  },
  {
    heading: "Heading",
    submenu: [
      { title: "Accordion 1", type: "simple", text: "Text", status: "status" },
      { title: "Accordion 2", type: "simple", text: "Text", status: "status" },
      { title: "Accordion 3", type: "accordian", text: "Text", status: "status" },
    ],
  },
  {
    heading: "Heading",
    submenu: [
      { title: "Accordion 1", type: "accordian", text: "Text", status: "status" },
      { title: "Accordion 2", type: "accordian", text: "Text", status: "status" },
      { title: "Accordion 3", type: "simple", text: "Text", status: "status" },
    ],
  },
  {
    heading: "Heading",
    submenu: [
      { title: "Accordion 1", type: "simple", text: "Text", status: "status" },
      { title: "Accordion 2", type: "accordian", text: "Text", status: "status" },
      { title: "Accordion 3", type: "accordian", text: "Text", status: "status" },
    ],
  },
  {
    heading: "Heading",
    submenu: [
      { title: "Accordion 1", type: "accordian", text: "Text", status: "status" },
      { title: "Accordion 2", type: "simple", text: "Text", status: "status" },
      { title: "Accordion 3", type: "simple", text: "Text", status: "status" },
    ],
  },
];

const HeaderTypography = styled(Typography)({
  fontSize: 14,
});

function Subscription() {
  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Subscription
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
          {data.map(({ heading, submenu }) => (
            <Grid item xs={6}>
              <Paper sx={{ p: 3 }}>
                <Stack direction="row" p={2} justifyContent="space-between">
                  <HeaderTypography>{heading}</HeaderTypography>
                  <MDButton color="info" size="small" sx={{ textTransform: "none" }}>
                    Log
                  </MDButton>
                </Stack>
                {submenu.map(({ title, type, text, status }) => (
                  <>
                    {type === "accordian" ? (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography sx={{ fontSize: 15, fontWeight: "500" }}>{title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack direction="row" justifyContent="space-between" p={2}>
                            <MDInput size="small" fullWidth sx={{ width: "75%" }} />
                            <MDButton color="info" size="small">
                              Submit
                            </MDButton>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <Stack direction="row" justifyContent="space-between" p={2}>
                        <Typography sx={{ fontSize: 14 }}>{text}</Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>{status}</Typography>
                      </Stack>
                    )}
                  </>
                ))}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Subscription;
