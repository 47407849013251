/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { useSnackbar } from "notistack";
import { api } from "util";
// import { useNavigate } from "react-router-dom";
// import MDBox from "components/MDBox";

export default function EditNewsImage({ selected, onClose }) {
  const imginputRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null)
  const [ImgId, setImgId] = useState("")
  const [ResponsData, setResponsData] = useState("");

  const [confirmation, setConfirmation] = useState(false);
  const [reloadData, setreloadData] = useState(false);

  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);
  // window.location.reload();
  // const navigate = useNavigate();

  const EditTopicreq = async () => {
    try {
      const { data } = await api.patch(`content/update/contant/Image`, {
        contantlId: selected?.NewsId,
        contantImage: ImgId
      });
      setResponsData(data);
      setreloadData(true);
    } catch (error) {
      setResponsData(error?.response?.data);
    }
  };

  useEffect(() => {
    setreloadData(false);
  }, []);

  const UploadImage = async (filedata) => {
    try {
      const { data } = await api.post(`/upload/img`, filedata,{headers: {'Content-Type': 'multipart/form-data'}});
      console.log(data?.resalt)
      setImgId(data?.resalt);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  const handleFileUpload = (e) => {
    if(!e.target.files.length) return;
    const file = e.target.files[0];
    setSelectedFile(file);
    
    const formData = new FormData();
    formData.append('image', file);
    setImgId("uploding Img")
    UploadImage(formData)
  }
  
  return (
    <Dialog
      open={!!selected}
      onClose={() => {
        setConfirmation(false);
        onClose();
      }}
      fullWidth
    >
      <DialogTitle>Edit News :- {selected?.Newstitle}</DialogTitle>
      <DialogContent>
        {!confirmation ? (
          <Stack spacing={2} mt={1}>
            <input ref={imginputRef} type='file' onChange={handleFileUpload} hidden />
            <Button onClick={() => imginputRef.current?.click()}>{selectedFile ? selectedFile.name : 'Upload Artical IMG'}</Button>
          </Stack>
        ) : (
          <Stack spacing={2} mt={1}>
            {ResponsData.status && <Typography>Confirmation</Typography>}
            {!ResponsData.status && <Typography>Error</Typography>}
            <Typography>{ResponsData?.message ?? "Something went wrong"}</Typography>
          </Stack>
        )}
      </DialogContent>

      {ImgId && <DialogActions>
        <Stack direction="row" spacing={2} alignItems="center">
          <MDButton
            component={Button}
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => {
              if (!confirmation) {
                setConfirmation(true);
                EditTopicreq();
              } else {
                setConfirmation(false);
                onClose();
                if (reloadData === true) {
                  window.location.reload();
                }
              }
            }}
          >
            {!confirmation ? "Submit" : "Close"}
          </MDButton>
        </Stack>
      </DialogActions>}

    </Dialog>
  );
}
