/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { api } from "util";
import { useSnackbar } from "notistack";
// import { useNavigate } from "react-router-dom";
// import MDBox from "components/MDBox";

export default function AddTopicModeal({ selected, onClose }) {
  const inputRef = useRef();
  // const [package1, setPackage1] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [topicName, settopicName] = useState("");
  const [ResponsData, setResponsData] = useState("");

  const [confirmation, setConfirmation] = useState(false);
  const [reloadData, setreloadData] = useState(false);
  const [FileId, setFileId] = useState("")
  const [sequqncE,setsequqncE] = useState(null)

  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);
  // window.location.reload();
  // const navigate = useNavigate();

  const ADDTopicreq = async () => {
    try {
      const { data } = await api.post(`/content/add/channel`, {
        channelName: topicName,
        imageId:FileId,
        sequqnce:sequqncE
      });
      setResponsData(data);
      setreloadData(true);
    } catch (error) {
      setResponsData(error?.response?.data);
    }
  };

  useEffect(() => {
    setreloadData(false);
  }, []);

  const [selectedFile, setSelectedFile] = useState(null)

  const UploadImage = async (filedata) => {
    try {
      const { data } = await api.post(`/upload/img`, filedata,{headers: {'Content-Type': 'multipart/form-data'}});
      console.log(data?.resalt)
      setFileId(data?.resalt);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  const handleFileUpload = (e) => {
    if(!e.target.files.length) return;
    const file = e.target.files[0];
    setSelectedFile(file);
    
    const formData = new FormData();
    formData.append('image', file);
    setFileId("uploding")
    UploadImage(formData)
  }

  return (
    <Dialog
      open={!!selected}
      onClose={() => {
        setConfirmation(false);
        onClose();
      }}
      fullWidth
    >
      <DialogTitle>Add Topic</DialogTitle>
      <DialogContent>
        {!confirmation ? (
          <Stack spacing={2} mt={1}>
              <TextField
                label="Topic Name"
                fullWidth
                value={topicName}
                onChange={(e) => settopicName(e.target.value)}
            />
            <TextField
                label="Sequence"
                fullWidth
                value={sequqncE}
                onChange={(e) => setsequqncE(e.target.value)}
            />
            <input ref={inputRef} type='file' onChange={handleFileUpload} hidden />

            <Button onClick={() => inputRef.current?.click()}>{selectedFile ? selectedFile.name : 'Upload'}</Button>

          </Stack>
        ) : (
          <Stack spacing={2} mt={1}>
            {ResponsData === "" && <Typography>Loding...</Typography>}
            {ResponsData && <Typography>Confirmation</Typography>}
            {ResponsData && <Typography>{ResponsData.message}</Typography>}
            {ResponsData?.status === false && <Typography>Error</Typography>}
            {ResponsData?.status === false && <Typography>{ResponsData.message}</Typography>}
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} alignItems="center">
          {FileId === "uploding" && (<MDButton
            component={Button}
            variant="gradient"
            color="info"
            size="medium"
          > Uploading... </MDButton>)}

          {FileId  && (<MDButton
            component={Button}
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => {
              if (!confirmation) {
                setConfirmation(true);
                ADDTopicreq();
              } else {
                setConfirmation(false);
                onClose();
                if (reloadData === true) {
                  window.location.reload();
                }
              }
            }}
          >
            {!confirmation ? "Submit" : "Close"}
          </MDButton>)}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
