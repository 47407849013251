/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import { api } from "util";
import { useSnackbar } from "notistack";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import { useNavigate } from "react-router-dom";
// import MDBox from "components/MDBox";

export default function EditNewsTopic({ selected, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [ResponsData, setResponsData] = useState("");
  const [checkedValues, setCheckedValues] = useState([]);
  const [topicvar, settopicvar] = useState([]);

  const [confirmation, setConfirmation] = useState(false);
  const [reloadData, setreloadData] = useState(false);

  // useEffect(() => {
  //   if(!selected) return;

  //   settitle(selected?.Newstitle)
  // }, [selected])

  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);
  // window.location.reload();
  // const navigate = useNavigate();

  const EditTopicreq = async () => {
    try {
      const { data } = await api.patch(`/content/update/contant/topic`, {
        contantlId: selected?.NewsId,
        contantTopic: checkedValues
      });
      setResponsData(data);
      setreloadData(true);
    } catch (error) {
      setResponsData(error?.response?.data);
    }
  };

  const GetTopic = async () => {
    try {
      const { data } = await api.get(`/content/get/genre?showtype=Dropdown`);
      settopicvar(data.resalt);
      return
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if(!selected) return;
    const oldArr = []
    selected?.NewsTopic?.map(({genreId}) => (oldArr.push(genreId)));
    setCheckedValues(oldArr)
    setreloadData(false);
    GetTopic();
  }, [selected]);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const currentIndex = checkedValues.indexOf(value);
    const newCheckedValues = [...checkedValues];
    
    if (currentIndex === -1) {
      newCheckedValues.push(value);
    } else {
      newCheckedValues.splice(currentIndex, 1);
    }

    setCheckedValues(newCheckedValues);
  }
  
  return (
    <Dialog
      open={!!selected}
      onClose={() => {
        setConfirmation(false);
        onClose();
      }}
      fullWidth
    >
      <DialogTitle>Edit News :- {selected?.Newstitle}</DialogTitle>
      <DialogContent>
        {!confirmation ? (
          <Stack spacing={2} mt={1}>
            <h4>Topic</h4>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {topicvar?.map(
                  ({
                    genreId,
                    genreName
                  }) => (
                  <FormControlLabel
                  key={genreId}
                  control={
                  <Checkbox
                  checked={checkedValues.includes(genreId)}
                  onChange={handleCheckboxChange}
                  value={genreId}
                  />
                }
                label={genreName}
                />
                  )
                )}
                </div>
            </div>
          </Stack>
        ) : (
          <Stack spacing={2} mt={1}>
            {ResponsData.status && <Typography>Confirmation</Typography>}
            {!ResponsData.status && <Typography>Error</Typography>}
            <Typography>{ResponsData?.message ?? "Something went wrong"}</Typography>
          </Stack>
        )}
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={2} alignItems="center">
          <MDButton
            component={Button}
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => {
              if (!confirmation) {
                setConfirmation(true);
                EditTopicreq();
              } else {
                setConfirmation(false);
                onClose();
                if (reloadData === true) {
                  window.location.reload();
                }
              }
            }}
          >
            {!confirmation ? "Submit" : "Close"}
          </MDButton>
        </Stack>
      </DialogActions>

    </Dialog>
  );
}
