/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { api } from "util";
import { useSnackbar } from "notistack";
// import { useNavigate } from "react-router-dom";
// import MDBox from "components/MDBox";

export default function AddUserModeal({ selected, onClose }) {
  // const [package1, setPackage1] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [UserName, setUserName] = useState("");
  const [PasswordForm, setPassword] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [EmailForm, setEmail] = useState("");
  const [NameForm, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [GenderForm, setGender] = useState("");
  const [AddressForm, setAddress] = useState("");
  const [UserTypeForm, setUserType] = useState("");
  const [ResponsData, setResponsData] = useState("");

  const [UserTvpeList, setUserTvpeList] = useState([]);

  const [confirmation, setConfirmation] = useState(false);
  const [reloadData, setreloadData] = useState(false);

  // window.localStorage.getItem("user")
  let ADs = [];
  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);
  // window.location.reload();
  // const navigate = useNavigate();

  const ADDUSER = async () => {
    try {
      const { data } = await api.post(`/User/add`, {
        Name: NameForm,
        CompanyName: companyName,
        User_name: UserName,
        Password: PasswordForm,
        Phone_number: Number(MobileNumber),
        Email: EmailForm,
        Gendes: GenderForm,
        Address: AddressForm,
        UserType: UserTypeForm,
      });
      console.log(UserTypeForm);
      setResponsData(data);
      setreloadData(true);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
      setreloadData(false);
    }
  };

  useEffect(() => {
    setreloadData(false);
    if (user.userType === "Administrator") {
      ADs = [
        { id: "Jv", nametypes: "Joint Venture" },
        { id: "Distributor", nametypes: "Distributor" },
        { id: "Dealer", nametypes: "Dealer" },
      ];
      setUserTvpeList(ADs);
    } else if (user.userType === "Jv") {
      ADs = [
        { id: "Distributor", nametypes: "Distributor" },
        { id: "Dealer", nametypes: "Dealer" },
      ];
      setUserTvpeList(ADs);
    } else if (user.userType === "Distributor") {
      ADs = [{ id: "Dealer", nametypes: "Dealer" }];
      setUserTvpeList(ADs);
    }
  }, []);

  return (
    <Dialog
      open={!!selected}
      onClose={() => {
        setConfirmation(false);
        onClose();
      }}
      fullWidth
    >
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        {!confirmation ? (
          <Stack spacing={2} mt={1}>
            <TextField
              label="User Name"
              fullWidth
              value={UserName}
              onChange={(e) => setUserName(e.target.value)}
            />

            <TextField
              label="Password"
              fullWidth
              value={PasswordForm}
              onChange={(e) => setPassword(e.target.value)}
            />

            <TextField
              label="Mobile Number"
              fullWidth
              value={MobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              placeholder="+91XXXXXXXXXX"
            />

            <TextField
              label="Email Address"
              fullWidth
              value={EmailForm}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              label="Name"
              fullWidth
              value={NameForm}
              onChange={(e) => setName(e.target.value)}
            />

            <FormControl fullWidth>
              <InputLabel>Gender</InputLabel>
              <Select
                value={GenderForm}
                label="Gender"
                onChange={(e) => setGender(e.target.value)}
                sx={{ height: 45 }}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Address"
              fullWidth
              value={AddressForm}
              onChange={(e) => setAddress(e.target.value)}
            />

            <TextField
              label="Company Name"
              fullWidth
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel>User Type</InputLabel>
              <Select
                value={UserTypeForm}
                label="User Type"
                onChange={(e) => setUserType(e.target.value, console.log(e.target.value))}
                sx={{ height: 45 }}
              >
                {UserTvpeList?.map(({ id, nametypes }) => (
                  <MenuItem key={id} value={id}>
                    {nametypes}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        ) : (
          <Stack spacing={2} mt={1}>
            {ResponsData.success && <Typography>Confirmation</Typography>}
            {!ResponsData.success && <Typography>Error</Typography>}
            <Typography>{ResponsData.message}</Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} alignItems="center">
          <MDButton
            component={Button}
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => {
              if (!confirmation) {
                setConfirmation(true);
                ADDUSER();
              } else {
                setConfirmation(false);
                onClose();
                if (reloadData === true) {
                  window.location.reload();
                }
              }
            }}
          >
            {!confirmation ? "Submit" : "Close"}
          </MDButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
