/* eslint-disable no-param-reassign */
import axios from "axios";
import constants from "config/constants";
import { Buffer } from "buffer";
import store from "../redux/store";
import { userLogin, userLogout } from "../redux/user/userSlice";

const api = axios.create({
  baseURL: constants.BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (request) => {
    let accessToken;

    let { user } = store.getState().userReducer;

    if (!user) {
      user = localStorage.getItem("user");
      if (user) user = JSON.parse(user);
    }

    if (user) {
      accessToken = user.accessToken;
    }
    // console.log("sh");
    // console.log(accessToken);

    request.headers.Authorization = accessToken;

    return request;
  },
  (err) => Promise.reject(err)
);

async function checkRefreshToken(config) {
  try {
    let accessToken;
    let refreshToken;
    let { user } = store.getState().userReducer;
    // const xDeviceID = localStorage.getItem("X-Device-ID") || "web";
    if (!user) {
      user = localStorage.getItem("user");
      if (user) user = JSON.parse(user);
    }

    if (user?.refreshToken) {
      accessToken = user.accessToken;
      refreshToken = user.refreshToken;
      refreshToken = Buffer.from(refreshToken).toString("base64");
      const { data } = await axios.post(
        `${constants.BASE_URL}/auth/refresh`,
        {
          Token: refreshToken,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );

      store.dispatch(userLogin(data));
      const delayRetryRequest = new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, config.retryDelay || 1000);
      });

      config.headers.Authorization = data.access_tokan;

      return delayRetryRequest.then(() => axios(config));
    }

    store.dispatch(userLogout());
    // console.log("LOGIBD");
    return Promise.reject();
  } catch (error) {
    console.log("...Error1", error);
    if (error.response?.status === 401) {
      // console.log("LOGIBDss");
      store.dispatch(userLogout());
    }
    return false;
  }
}

api.interceptors.response.use(
  (response) => response,
  (err) => {
    const { config, message } = err;
    // if (!config || !config.retry) {
    //   return Promise.reject(err);
    // }
    if (err.response?.status === 401) {
      return checkRefreshToken(config);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
      return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));
  }
);

export default api;
