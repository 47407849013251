/* eslint-disable import/order */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import {
  Button,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import { api } from "util";
import { useSnackbar } from "notistack";
// import UserSearchTable from "./UserSearchTable";
import MDButton from "components/MDButton";
import AddTopicModal from "layouts/News/AddNewsModal";
import EditNewsArtical from "layouts/News/EditNewsArtical";
import EditNewsDescription from "layouts/News/EditNewsDescription";
import EditNewsTitle from "layouts/News/EditNewsTitle";
import EditNewsImage from "layouts/News/EditNewsImage";
import EditNewsTopic from "layouts/News/EditNewsTopic";

// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import moment from "moment";

function News() {
  const { enqueueSnackbar } = useSnackbar();
  const [searchRes, SetsearchRes] = useState();
  const [username, setUsername] = useState();

  const [addingUser, setAddingUser] = useState();
  const [NewsTitleProp, setNewsTitleProp] = useState();
  const [Newsimage, setNewsimage] = useState();
  const [NewsDescriptionProp, setNewsDescriptionProp] = useState();
  const [NewsArticalProp, setNewsArticalProp] = useState();
  const [NewsEditTopix, setNewsEditTopix] = useState();
  // const [imgData, setimgData] = useState();
  // const [ClintUserID, setClintUserID] = useState();
  // const [PackAlot, setPackAlot] = useState();
  // const [getPackassinData, setgetPackassinData] = useState();
  // const [Walletdata, setWalletdata] = useState();

  const SarchTopic = async (topicName) => {
    try {
      if (topicName === "") {
        const { data } = await api.get(`content/get/news`);
        SetsearchRes(data.resalt);
      } else {
        const { data } = await api.get(`content/get/news`, {
          params: { newstitel: topicName },
        });
        SetsearchRes(data.resalt);
      }
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.message ?? "Something went wrong",
        {
          variant: "error",
        }
      );
    }
  };

  const DisableNewsFeed = async (DeleteId,NewsStatus) => {
    if (NewsStatus) {
      NewsStatus = "enable"
    } else {
      NewsStatus = "disable"
    }
    try {
      const { data } = await api.delete(`content/status/news/` + DeleteId+"/"+NewsStatus);
      enqueueSnackbar(data?.message, { variant: "success" });
      SarchTopic(username);
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.message ?? "Something went wrong",
        {
          variant: "error",
        }
      );
    }
  };

  useEffect(() => {
    SarchTopic("");
  }, []);

  return (
    <DashboardLayout>
      <AddTopicModal
        selected={addingUser}
        onClose={() => setAddingUser(null)}
      />
      <EditNewsArtical
        selected={NewsArticalProp}
        onClose={() => setNewsArticalProp(null)}
      />
      <EditNewsDescription
        selected={NewsDescriptionProp}
        onClose={() => setNewsDescriptionProp(null)}
      />
      <EditNewsTitle
        selected={NewsTitleProp}
        onClose={() => setNewsTitleProp(null)}
      />
      <EditNewsImage
        selected={Newsimage}
        onClose={() => setNewsimage(null)}
      />
      <EditNewsTopic
        selected={NewsEditTopix}
        onClose={() => setNewsEditTopix(null)}
      />
      {/* <ViewStateIconModal selected={imgData} onClose={() => setimgData(null)} /> */}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  News
                </MDTypography>
              </MDBox>
              <MDBox pt={3} pl={5}>
                <Stack direction="row" spacing={4}>
                  <TextField
                    label="News ID"
                    value={username}
                    onChange={(e) => {
                      SarchTopic(e.target.value);
                      setUsername(e.target.value);
                    }}
                  />
                  <MDButton
                    component={Button}
                    variant="gradient"
                    color="info"
                    size="medium"
                    type="submit"
                    onClick={() => setAddingUser({})}
                  >
                    Add News
                  </MDButton>
                </Stack>
              </MDBox>
              <TableContainer component={Paper} sx={{ mt: 3, px: 3 }}>
                <Table>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "900" }}>Title</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Category</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>News Type</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Publisher</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Created @</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Operations</TableCell>
                  </TableRow>
                  <TableBody>
                  {searchRes?.map(
                      ({
                        contentId,
                        Title,
                        description,
                        ContantType,
                        article,
                        CreatedBy,
                        Genres,
                        Status,
                        Inserted_on,
                      }) => (
                        <TableRow key={contentId}>
                          <TableCell>{Title}</TableCell>
                          <TableCell>{Genres?.map(({genreName}) => (genreName+"|"))}</TableCell>
                          <TableCell>{ContantType}</TableCell>
                          <TableCell>{CreatedBy}</TableCell>
                          <TableCell>
                            {moment(Inserted_on*1000).format("YYYY-MM-DD HH:mm:ss")}
                          </TableCell>
                          <TableCell>
                          <Stack direction="column" spacing={1}>
                            {Status ? <MDButton
                              color="info"
                              type="submit"
                              onClick={() => {
                                DisableNewsFeed(contentId,false)
                              }}
                            >
                              Disable News
                            </MDButton>:<MDButton
                              color="info"
                              type="submit"
                              onClick={() => {
                                DisableNewsFeed(contentId,true)
                              }}
                            >
                              Enable News
                            </MDButton>}
                          </Stack>
                          </TableCell>
                          <TableCell>
                          <Stack direction="column" spacing={1}>
                            <MDButton
                              color="info"
                              type="submit"
                              onClick={() => {
                                setNewsTitleProp({ NewsId :contentId,Newstitle: Title})
                              }}
                            >
                              View/Edit Title
                            </MDButton>
                            <MDButton
                              color="info"
                              type="submit"
                              onClick={() => {
                                setNewsDescriptionProp({ NewsId :contentId,Newstitle: Title,NewsDiscription:description})
                              }}
                            >
                              View/Edit Description
                            </MDButton>
                            <MDButton
                              color="info"
                              type="submit"
                              onClick={() => {
                                setNewsArticalProp({ NewsId :contentId,Newstitle: Title,NewsContant:article})
                              }}
                            >
                              View/Edit Article
                            </MDButton>
                          </Stack>
                          </TableCell>
                          <TableCell>
                          <Stack direction="column" spacing={1}>
                            <MDButton
                              color="info"
                              type="submit"
                              onClick={() => {
                                // setTopicedit({ NewsId :contentId,Newstitle: Title,NewsContant:article})
                              }}
                            >
                              View/Edit Tags
                            </MDButton>
                            <MDButton
                              color="info"
                              type="submit"
                              onClick={() => {
                                setNewsEditTopix({ NewsId :contentId,Newstitle: Title,NewsTopic:Genres})
                              }}
                            >
                              View/Edit Topic
                            </MDButton>
                            <MDButton
                              color="info"
                              type="submit"
                              onClick={() => {
                                setNewsimage({ NewsId :contentId,Newstitle: Title})
                                // setTopicedit({ NewsId :contentId,Newstitle: Title,NewsContant:article})
                              }}
                            >
                              Edit Image
                            </MDButton>
                          </Stack>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <UserSearchTable users={searchRes} /> */}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default News;
